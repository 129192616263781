window.App || (window.App = {});
var popoverOptions = {
  trigger: 'focus',
  placement: 'top'
};

$(function() {
  const operate = function(a, operator, b) {
    switch(operator) {
      case '<=':
        return a <= b
      case '<':
        return a < b
      case '>':
        return a > b
      case '>=':
        return a >= b
      default:
        return null
    }
  }
  const selectors = {
    'periodForm': 'form.edit_management_fees_period'
  }
  selectors.questionAverageGAV = `${selectors.periodForm} #mft-question-AverageGAV`
  selectors.questionAverageNAV = `${selectors.periodForm} #mft-question-AverageNAV`
  selectors.allFields = [
    `${selectors.periodForm} input`,
    `${selectors.periodForm} select`,
    `${selectors.periodForm} textarea`,
  ].join(', ')
  selectors.submitButtons = `${selectors.periodForm} input[type=submit]`

  const periodForm = $(selectors.periodForm)

  // Only use this on the MFT questionnaire edit page.
  if (periodForm.length == 0) {
    return
  }

  // ====== Watch for dirty and warn on page leave ======
  $(document).on('submit', selectors.periodForm, function(e) {
    $(periodForm).data('submitting', true)
  })
  window.addEventListener('beforeunload', function(e) {
    if ( $(periodForm).data('changed') && ! $(periodForm).data('submitting') ) {
      e.preventDefault()
      e.returnValue = 'Please save the changes before leaving this page.';
      return 'Please save the changes before leaving this page.'
    }
  })
  $(document).on('change', selectors.allFields, function(event) {
    $(periodForm).data('changed', true)
  })
  // ----------------------------------------------------

  // ====== Conditional content display ======
  const toggleConditionalContent = function(questionInputId, target, showIfMatch, effectDuration) {
    let selectedValue = document.getElementById(questionInputId).value
    if(selectedValue.match(new RegExp(showIfMatch))) {
      $(target).show(effectDuration);
    }else{
      $(target).hide(effectDuration);
    }
  }
  const registerConditionalContent = function(
    questionId,
    targetId,
    showIfMatch = '^Yes.*',
    effectDuration = 800
  ) {
    const questionInput = $(questionId).children('select')
    const questionInputId = $(questionInput).attr('id')
    const target = $(targetId)
    questionInput.on('change', function(e) {
      toggleConditionalContent(questionInputId, target, showIfMatch, effectDuration)
    })
    toggleConditionalContent(questionInputId, target, showIfMatch, 0)
  }
  // Commitment fees
  registerConditionalContent('#mft-question-FeesDuringCommitmentYesNo', '#mft-table-ManagementFeesDuringCommitmentPeriod')
  // Does fund apply performance fees, if no hide all content of Section III
  registerConditionalContent('#mft-question-PerformanceFees_Applicable', '#mft-section-PerformanceFeesFieldset')
  /**
   * Does your fund calculate and distribute performance fees at,
   * If ONLY AT TERMINATION, please move to Section III B - Performance fees at termination
   * If ONLY PERIODICALLY show section A
   * If BOTH, please complete Sections III A and B.
   */
  registerConditionalContent(
    '#mft-question-PerformanceFees_WhenPerformanceFeesDistributed',
    '#mft-section-PerformanceFeesAtTermination',
    '(^Only at termination)|(^Both)'
  )
  registerConditionalContent(
    '#mft-question-PerformanceFees_WhenPerformanceFeesDistributed',
    '#mft-section-PeriodicPerformanceFees',
    '(^Only periodically)|(^Both)'
  )
  // Reporting TGER
  registerConditionalContent('#mft-question-ReportTGER', '#mft-section-TgerNotReportingReason', '(^No)|(^Do not report any TGER)')
  // Catch up provision
  registerConditionalContent('#mft-question-CatchUpProvision', '#mft-table-CatchUpProvision')
  // -----------------------------------------

  // ====== Applicable rows required, inapplicable rows disabled ======
  const toggleRowRequired = function(element, required = false) {
    $(element).closest('tr').find('input.mft-input-in-column-ActualFees').each(function() {
      if ($(this).attr('type') !== 'hidden') {
        $(this).attr('required', required)
        $(this).attr('aria-required', required)
        if (required) {
          $(this).addClass('required')
          $(this).addClass('validate')
          $(this).removeClass('optional')
        } else {
          $(this).removeClass('required')
          $(this).addClass('optional')
        }
        $(this)[0].dispatchEvent(new Event('blur', { 'bubbles': true }))
      }
    })
  }
  const toggleRowDisabled = function(element, disabled = false) {
    $(element).closest('tr').find('input, select:not(.mft-input-in-column-Applicable), textarea').each(function() {
      if ($(this).is('select, input[type="checkbox"]')) {
        $(this).prop('disabled', disabled)
        let myId = $(this).attr('id')
        let hiddenInput = $(this).parents('td').find(`input#${myId}[type=hidden]`)
        hiddenInput.val(document.getElementById(myId).value)
        hiddenInput.prop('disabled', !disabled)
      } else if ($(this).attr('type') !== 'hidden') {
        $(this).prop('readonly', disabled);
      }
    })
  }
  const applyToggles = function(element, applicable) {
    switch(applicable) {
      case 'No':
        toggleRowDisabled(element, true)
        toggleRowRequired(element, false)
        break
      case 'Yes':
        toggleRowDisabled(element, false)
        toggleRowRequired(element, true)
        break
      default:
        toggleRowDisabled(element, false)
        toggleRowRequired(element, false)
    }
  }
  // ------------------------------------------------------------------

  $(document).on('change', 'select.mft-input-in-column-Applicable', function(event) {
    const applicable = document.getElementById($(this).attr('id')).value;
    applyToggles(this, applicable)
  })
  // ---------------------------------------------------------

  // ====== Textarea resizing on focus ======
  $(document).on('focus', '.section > .input textarea:not([readonly])', function() {
    $(this).animate({'height' : '200px'});
  });

  $(document).on('focus', 'td > .input textarea:not([readonly])', function() {
    $(this).parent().animate({'height' : '200px'});
  });

  $(document).on('blur', '.section > .input textarea:not([readonly])', function() {
    $(this).animate({'height' : '50px'});
  });

  $(document).on('blur', 'td > .input textarea:not([readonly])', function() {
    $(this).parent().animate({'height' : '40.75px'});
  });
  // ----------------------------------------

  // ====== Validations ======
  const buildErrorSummary = function() {
    let total = 0
    let required = 0
    let numeric = 0
    let formatted = 0
    $(periodForm).find('input, select, textarea').filter(':not(:hidden)').each(function(index, element) {
      if ($(element).filter(':invalid').length > 0) {
        total += 1

        if ($(element).is(':required') && !$(element).is('.has-value')) {
          required += 1
        } else if ($(element).is('[type=number]')) {
          numeric += 1
        } else {
          formatted += 1
        }
      }
    })
    return {
      'total': total,
      'required': required,
      'numeric': numeric,
      'formatted': formatted,
    }
  }
  const updateErrorSummaryValue = function() {
    const errorSummaryInput = $('#management_fees_period_error_summary');
    const errorSummary = buildErrorSummary();
    $(errorSummaryInput).val(JSON.stringify(errorSummary));
  }

  $(document).on('change', selectors.allFields, function(event) {
    updateErrorSummaryValue();
  })
  $(document).ready(function(event) {
    updateErrorSummaryValue();
  })
  const doNumericComparisonValidation = function(selectorA, operator, selectorB, message) {
    const inputA = $(`#mft-question-${selectorA} input`)
    const inputB = $(`#mft-question-${selectorB} input`)
    const numA = $(inputA).val() === '' ? false : Number($(inputA).val())
    const numB = $(inputB).val() === '' ? false : Number($(inputB).val())

    const errorMessage = message

    if (numA && numB && !operate(numA, operator, numB)) {
      $(inputA)[0].setCustomValidity(errorMessage);
      $(inputA)[0].reportValidity();
    } else {
      $(inputA)[0].setCustomValidity('');
    }
    updateErrorSummaryValue();
  }
  const registerNumericComparisonValidation = function(selectorA, operator, selectorB, message) {
    $(document).on(
      'change',
      `${selectors.periodForm} #mft-question-${selectorA} input, ${selectors.periodForm} #mft-question-${selectorB} input`,
      function(event) {
        doNumericComparisonValidation(selectorA, operator, selectorB, message)
      }
    )
    doNumericComparisonValidation(selectorA, operator, selectorB, message)
  }
  $(document).ready(function(event) {
    registerNumericComparisonValidation('AverageNAV', '<=', 'AverageGAV', 'Average NAV cannot exceed Average GAV')
  })
  // -------------------------
});
