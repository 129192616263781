import 'select2'

export default class Select2Input {
  constructor(id) {
    const field = document.getElementById(id);
    if (!field) {
      console.debug(`Select2Input could not find id: ${id}`)
    } else {
      this.id = id
      $(field).select2({
        templateResult: function (data) {
          // If possible, add a span wrapper with the same css-class as the original '<option>' element.
          if (!data.element) {
            return data.text
          }
          return $('<span></span>').text(data.text).addClass(data.element.className)
        }
      });
      this.registerEvents()
    }
  }

  registerEvents() {
    const container = `#${this.id} ~ .select2-container`
    $(document).on('focus', container, function (e) {
      $(this).siblings('select:enabled').select2('open')
    })

    const selection = `#${this.id} ~ .select2 .select2-selection.select2-selection--single`
    $(document).on('focus', selection, function (e) {
      $(this).closest(".select2-container").siblings('select:enabled').select2('open')
    })

    var $childMenu = $(`#${this.id}`)
    var parentId = $childMenu.data('conditional-choice-parent')

    if (parentId) {
      var $parentMenu = $(`select[id*='${parentId}']`)
      var filterableParentValues = $childMenu.data('filterable-parent-values')

      $parentMenu.on('change', function (e) {
        // On parent option change, clear the current selection.
        $childMenu.val('').trigger('change')
      });

      $childMenu.on('select2:opening', function (e) {
        // 1. Get parent value
        // - Ex: Vehicle Detail: dynamic value from a select menu on the page.
        // - Ex: Vehicle Allocations: static value that is passed in from the view.
        var parentValue = $parentMenu.find(':selected').val() || parentId
        var filterableChildrenCount = 0

        // 2. Disable children that are not decendents of parentValue
        // - filterableParentValues not provided? Apply parent-child filtering without restrictions.
        var filterableCount = Array.isArray(filterableParentValues) ? filterableParentValues.length : 0
        if (filterableCount == 0 || filterableParentValues.includes(parentValue)) {
          Array.from($childMenu.children()).forEach(option => {
            var disabledCondition = option.label.trim().length > 0 && option.label != parentValue
            $(option).prop('disabled', disabledCondition)
            if (option.label == parentValue) { filterableChildrenCount++ }
          })
        }

        // 3. Enable all options, if no children were found.
        if (filterableChildrenCount == 0) {
          Array.from($childMenu.children()).forEach(option => {
            $(option).prop('disabled', false)
          })
        }
      })
    }

    // steal focus during close - only capture once and stop propogation
    $childMenu.on('select2:closing', function (e) {
      $childMenu.data("select2").$selection.one('focus focusin', function (e) {
        e.stopPropagation()
      })
    })
  }
}
