// Copied and modified from styleguide: https://github.com/Booreiland/inrev-modules/blob/master/src/components/c-dropdown/c-dropdown.js
// Later we should import via the npm package
const calculateDropdownWidth = function() {
  const _$dropdownGroup = $('.js-dropdown-group');
  const longestWidth = Math.max.apply(Math, _$dropdownGroup.find('.c-dropdown__item:not(.active)').map(function() {
    return $(this).outerWidth();
  }).get());
  // Absolute parent does not respect responsive child's width, fit-content isn't supported in Edge/IE
  _$dropdownGroup.closest('.js-dropdown-menu').css({
    'min-width': `calc(${longestWidth} + 54}px + 6rem)` // Flexible width + delete + padding
  });
};

function toggleDropdownMenu (e) {
  const _$dropdown = $(e.currentTarget).closest('.js-dropdown');
  if (_$dropdown.hasClass('is-open')) {
    _$dropdown.removeClass('is-open');
  } else {
    _$dropdown.addClass('is-open');
  }
  calculateDropdownWidth();
};

$(document).ready(function() {
  const _dropdowns = '.js-dropdown-toggle, .js-dropdown-cancel .js-dropdown-apply-filters';

  $(document).on('click', _dropdowns, function(e) {
    e.preventDefault();
    toggleDropdownMenu(e);
  });

  $(document).on('click', function(e) {
    if (!$(e.target).is('.js-dropdown.is-open *')) {
      $('.js-dropdown').removeClass('is-open');
    }
  });
});
