import 'core-js/proposals/url';
import Toast from './toast'
import { loadingAnimation } from './html_templates'
import { saveAs } from 'file-saver';
import { xhrResponseURL } from './utils'

$(document).on('click', 'a:not(.js-no-progress)', (event) => {
  var link = event.currentTarget;

  // show a progress indicator on file downloads
  if ( /xlsx/.test(link.href) && !/amazon/.test(link.href) ) {
    var is_long_download = link.getAttribute('data-long-download') === 'true';
    var xhr_form_selector = link.getAttribute('data-xhr-form-selector');
    var xhr_form_data = null;
    var csrf = null;
    if (xhr_form_selector) {
      xhr_form_data = $(xhr_form_selector).serialize();
      csrf = document.querySelector('meta[name="csrf-token"]').content;
    }

    var req = new XMLHttpRequest();
    var cursor = $(link).css("cursor");
    $(link).css("cursor", "progress");

    if (xhr_form_data && csrf) {
      req.open('POST', link.href, true);
      req.setRequestHeader('X-CSRF-Token', csrf);
      req.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    } else {
      req.open('GET', link.href, true);
    }
    let toast = new Toast('body')
    let message = 'Your file is being prepared...';
    if (is_long_download) {
      message = 'This could take a few minutes...';
    }
    toast.add({
      title: 'Downloading',
      message: message,
    })
    req.addEventListener("progress", function (evt) {
      if(evt.lengthComputable) {
        var percentComplete = evt.loaded / evt.total;
      }
    }, false);
    req.responseType = "blob";
    req.onreadystatechange = function () {
        if (req.readyState === 4) {
          $(link).css("cursor", cursor);
          toast.updateState('downloaded', true)
          if (req.status === 200) {
            var link_url = new URL(link.href);
            var path = link_url.pathname.split('/')
            var filename = path[path.length -1];
            var disposition = req.getResponseHeader('Content-Disposition');
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, '');
                }
            }
            saveAs(new Blob([req.response], { type: req.response.type }), filename);
          } else if ([401, 422].includes(req.status)) {
            // TODO: Research if its worth to add some logic for the 403 error.
            window.location.reload()
          } else {
            var alert_dialog = App.alert || window.alert
            var reader = new FileReader;
            reader.onload = function(evt) {
              alert_dialog('Unexpected error ('+req.status+'): '+ evt.target.result);
            };
            reader.readAsText(req.response)
          }
        }
    };
    req.withCredentials = true;
    req.send(xhr_form_data);
    event.preventDefault();
  }
});

$(window).on('focus', (event) => {
  $(event.target).css("cursor", "initial");
});

$(document).on('ajax:before', '[data-loading]', (event) => {
  const spinner_container = $(event.target.dataset['target'] || event.target)
  const spinner_html = loadingAnimation(0, event.target.dataset['message'], true)
  // ref count this so we show/hide the spinner only when necessary
  const refcount = +spinner_container.data('dlcount') || 0
  if (refcount == 0) {
    spinner_container.find('> .js-loading').remove();
    spinner_container.prepend(spinner_html);
  }
  spinner_container.data('dlcount', refcount + 1)
});
$(document).on('ajax:success', '[data-loading]', (event) => {
  const detail = event.detail;
  const responseText = detail[0], status = detail[1], xhr = detail[2];
  const selector = event.target.dataset['replace']
  const dataResponseUrl = event.target.dataset['responseUrl']
  if (selector) {
    $(selector).html($(responseText).find(selector).html());
  }
  const responseURL = dataResponseUrl || xhrResponseURL(xhr)
  if (responseURL) {
    history.replaceState(null, '', responseURL);
  }
});
$(document).on('ajax:complete', '[data-loading]', (event) => {
  const spinner_container = $(event.target.dataset['target'] || event.target);
  // ref count this so we show/hide the spinner only when necessary
  const refcount = +spinner_container.data('dlcount') || 0
  spinner_container.data('dlcount', refcount - 1)
  if (refcount <= 0) {
    spinner_container.find('> .js-loading').remove();
    spinner_container.data('dlcount', null)
  }
});
