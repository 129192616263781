// Show hide hints in `.b-form--annotated` based on focus state
// Delay blur to allow clink events within hints to trigger
export function initHints() {
  const annotatedContainer = '.b-form--annotated'
  const $inputs = $(`${annotatedContainer} input, ${annotatedContainer} select, ${annotatedContainer} textarea`)
  /**
   * toggleHint - Toggle hint visibility with `visible` class
   *
   * @param  {NODE} {target} input DOM element
   * @return NA
   */
  function toggleHint(state='show', event) {
    const _input = event ? event.target : this
    const visibleClass = 'visible'
    const _$input = $(_input)
    $(`.b-form__helper.${visibleClass}`).removeClass(visibleClass)
    const $hint = _$input
      .closest('.b-form__group')
      .children('.b-form__helper:not(.b-form__helper--error)')
      .first()
    if ($hint.find('.b-form__helper-content').is(':empty')) return
    let classActionMethod = state === 'show' ? 'addClass' : 'removeClass'
    setTimeout(() => {
      $hint[classActionMethod](visibleClass)
      moveHintIntoView(_input, $hint[0])
    }, 1)
  }

  function moveHintIntoView(input, hint) {
    if (!input || !hint) return
    let classActionMethod = 'remove'
    if (hint.getBoundingClientRect().bottom > window.innerHeight) {
      classActionMethod = 'add'
    }
    hint.classList[classActionMethod]('position-bottom')

    classActionMethod = 'remove'
    if (hint.getBoundingClientRect().right > window.innerWidth) {
      classActionMethod = 'add'
    }
    hint.classList[classActionMethod]('position-below')
  }

  const delayedToggle = (event) => {
    setTimeout(() => { toggleHint('hide', event) }, 100)
  }

  // Add events for select2 inputs
  const select2Selector = '.select2'
  const $annotatedForm = $('.b-form--annotated')
  $annotatedForm
    .on( 'select2:opening', toggleHint )
    .on( 'select2:closing', toggleHint )

  // Add events for other/all input types
  ;[
    $inputs,
    $('.select2').parent(),
    $(`radiogroup label, ${annotatedContainer} .c-checkbox`),
  ].forEach(($i) => {
    $i.on( 'focus', toggleHint.bind(this, 'show') )
  })

  // Add mouseenter event for entire input group so the hint is triggered
  // and kept in place while mouse is over input, label, etc
  // Do not hide hint on mouse out for better experience
  // Hint is made not visible when another hint is shown
  $('.b-form__group')
    .on( 'mouseenter', toggleHint.bind(this, 'show') )

  $('.b-form__helper')
    .on( 'mouseenter', toggleHint.bind(this, 'show') )


}
