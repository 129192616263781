import { createEvent } from '../utils'

// Collapse/expand filter UI
export function handleFilterToggle(event) {
  console.warn('handleFilterToggle', event)
  const $toggleBtn = $(event.currentTarget)
  const $content = $('.j-main-content').find('.b-report__content, .l-layout__content-main')
  $toggleBtn.toggleClass('active')
  const $filters = $('.j-aside')
  $content.toggleClass('expanded')
  $filters.toggleClass('collapsed')

  // Store toggle state in a cookie
  document.cookie = `filters_collapsed=${!!$filters.hasClass('collapsed')};path=/`

  // Force charts to reflow into new parent width
  App.Charts.instances.forEach(chart => chart.resizeToContainer())
}
