export function initLabels() {
  const $scope = $('.b-form--floating-labels')
  const inputs = ':input'

  const checkForVal = (input) => {
    let action = 'removeClass'
    if ($(input).val()) {
      action = 'addClass'
    }
    $(input)[action]('has-value')
  }

  // we need a class for floating labels
  // that knows if the have a value present or not
  $scope.find(inputs).each((i, el) => {
    checkForVal(el)
  })

  $scope.on('change'. inputs, (event) => {
    checkForVal(event.target)
  })

  // select2 injects some markup after the select tag abd before the label
  // tag. We need to have the label directly after the select for
  // floating labels to work
  $scope.find('.b-form__label.select2').each((i, el) => {
    const $parent = $(el).parent()
    $parent.append($(el).detach())
  })
}
