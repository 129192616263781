/* eslint no-console:2 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import './extensions/index'

import 'core-js/proposals/url'
import 'jquery'
window.$ = jQuery
window.jQuery = jQuery
import 'dropzone'
import 'popper.js'
import 'bootstrap'

import moment from 'moment'
window.moment = moment
import Pikaday from 'pikaday'
window.Pikaday = Pikaday
import Rails from '@rails/ujs'
window.Rails = Rails
import intlTelInput from 'intl-tel-input'
window.intlTelInput = intlTelInput
Rails.start()

// Establish required globals
import Highcharts from 'highcharts'
require('highcharts/modules/map')(Highcharts);
import 'highcharts'

window.Highcharts = Highcharts
import AjaxModal from './ajax_modal_rails'
window.AjaxModal = AjaxModal

// Establish 'App' as namespace
import './namespace'

// Menu State
import './menu_state'

import './init.js'
import './asset_files'
import './data_loading_hooks'
import './dropdown.js'
import './error_handler'
import './menu.js'
import './period_warnings.js'
import './remote-checkboxes'
import './sign_off.js'

import './management_fees_questionnaire.js'

import InputRange from './input_range'
App.InputRange = InputRange

import InputRadius from './input_radius'
App.InputRadius = InputRadius

import './filters'
import './charts/_index'
import './forms'

if (App.reportAjaxError) {
  $(document).on('ajax:error', App.reportAjaxError);
}

// DataTables https://datatables.net/
import * as DataTable from 'datatables.net'
import FixedColumns from 'datatables.net-fixedcolumns'
import DataTableWrapper from './datatables'
window.DataTable = DataTable
App.DataTable = DataTableWrapper

import MultiSelectInput from './multi_select/MultiSelectInput'
App.MultiSelect = App.MultiSelect || {}
App.MultiSelect = {
  Input: MultiSelectInput,
  instances: App.MultiSelect.instances || {},
}
