/**
 * const updateState - Update state of other input
 *
 *
 * @param  {Node}    input          Input element
 * @param  {Boolean} otherSelected  If "other" is selected in related select
 * @return NA
 */
export function updateState(input, otherSelected, initing=false) {
  const $selectInput = $(input)
  const $otherInputWrapper = $selectInput.closest('.b-form__group').next()
  const $specifyOtherInput = $otherInputWrapper.find('input, textarea')
  const $otherSelect = $otherInputWrapper.prev()

  if (!$specifyOtherInput.length) {
    throw new Error(`No "specify other" input field found for ${$selectInput.attr('name')}`)
  }

  // see IN-109
  if (initing && $selectInput.is('select') && $specifyOtherInput.val()) return

  if (otherSelected) {
    $otherInputWrapper.addClass('active')
    // Add timeout to allow input to animate in
    // there was a bug where the select2 specify input was
    // not appearing
    setTimeout(() => {
      $otherInputWrapper
        .prop('required', true)
      if (!initing)
        $specifyOtherInput.focus()
    }, 500)
    $otherSelect.addClass('with-other-active')
  } else {
    $otherInputWrapper
      .removeClass('active')
      .find('input, textarea')
      .removeClass('validate has-value is-dirty')
      .attr('required', false)
      .removeAttr('valid')
      .val('')
    $otherSelect.removeClass('with-other-active')
  }

  if (initing) {
    validateOtherInput( $specifyOtherInput.val(), $specifyOtherInput, $selectInput )
    registerShowHideEvents($selectInput, $specifyOtherInput)
  }
  updateHelperText({
    $input: $specifyOtherInput,
    selectedValue: $selectInput.val()
  })
}

/**
 * const checkInputOtherState - Determine if "other" has been selected and
 *                              update state accordingly
 *
 * @param  {Object} event    Original DOM event
 * @param  {Node} input=null input element
 * @return NA
 */
export function checkInputOtherState(event, input=null, initing=false) {
  const _input = input ? input : this
  updateState( _input, valueIsOther($(_input)) ? 1 : 0, initing )
}

export const valueIsOther = ($input) => {
  if ($input.is('input[type=checkbox]')) {
    return $input.is(':checked')
  }
  const match = $input
    .find('option:selected')
    .val()
    .match( /other|adjusted/gi )
  return match && match.length
}

export function updateValue(event, $selectInput, $specifyOtherInput, initing=false) {
  const value = $specifyOtherInput.val()
  // If there's no value and this is run on page load, do nothing
  // There was a bug where if the input didn't have a value on load,
  // the select2 value appeared to be 'other'
  if (!value && initing) return
  // If User has entered a value, remove helper text
  updateHelperText({$input: $specifyOtherInput, selectedValue: $selectInput.val(), remove: value})
  const selectedValue = $selectInput.val()
  const newOptionText = value.length ? `${selectedValue} - ${value}` : selectedValue
  if ($selectInput.hasClass('select2')) {
    $selectInput.next().find('.select2-selection__rendered').html(newOptionText)
  }
  const otherOption = $selectInput.find('option[value*=Other]')
  if (otherOption) otherOption.innerHTML = newOptionText
}

export const showHideSpecifyInput = ($selectInput, $specifyOtherInput, show=true) => {
  if ($specifyOtherInput.val().trim()) {
    let classActionMethod = 'removeClass'
    if(show && valueIsOther($selectInput)) {
      classActionMethod = 'addClass'
    }
    $specifyOtherInput.parent()[classActionMethod]('active')
  }
}

export const getHelperElement = ($input) => {
  return $input.next('.b-form__helper')
}

export const updateHelperText = ({$input, selectedValue='other', remove=false}) => {
  let html = `Value must be specified when <em>${selectedValue}</em> is selected`
  // update helper message and valid attr so input is styled as valid
  if (remove) {
    html = ''
    $input.attr('valid', true)
  } else {
    $input.attr('valid', false)
  }
  getHelperElement($input).html(html)
}

export const validateOtherInput = (isValid, $specifyOtherInput, $selectInput) => {
  if (isValid && $specifyOtherInput.val()) {
    $specifyOtherInput.attr('valid', true)
  } else {
    $specifyOtherInput
      .addClass('is-dirty validate')
      .attr('valid', false)
  }
  updateHelperText({$input: $specifyOtherInput})
}

export const registerShowHideEvents = ($selectInput, $specifyOtherInput) => {
  // Events that show/hide specify input on focus/blur
  $selectInput.on('focus', () => showHideSpecifyInput($selectInput, $specifyOtherInput, true))
  $selectInput.on('select2:opening', () => showHideSpecifyInput($selectInput, $specifyOtherInput, true))
  $specifyOtherInput.on('blur', () => showHideSpecifyInput($selectInput, $specifyOtherInput, true))
}

// Add events to all elements on page load
export function initOtherOption() {
  $('select.j-input-with-other, input.j-input-with-other')
    .each((i, el) => {
      initOtherOptionForElement(el)
    })
}

// Add events to individual elements either on page load or when injected on the page.
export function initOtherOptionForElement(el) {
  const $elementInput = $(el)
  var $otherInput = null
  if(el.type == 'checkbox') {
    $otherInput = $elementInput.parent().parent().parent().next().find('input, textarea')
  } else {
    $otherInput = $elementInput.parent().next().find('input, textarea')
  }
  checkInputOtherState(null, el, true)
  updateValue(null, $elementInput, $otherInput, true)
  $elementInput.on('change', checkInputOtherState.bind(el))
  $otherInput.on('input change', () => {
    updateValue(null, $elementInput, $otherInput, false)
  })
}
