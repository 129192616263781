export default class CurrencyInput {
  constructor(id) {
    this.VALIDITY_MESSAGE = 'Please enter a valid number'
    const field = document.getElementById(id);
    if (!field) {
      console.debug(`CurrencyInput could not find id: ${id}`)
    } else {
      this.$currencyInput = $(`#${id}`)
      this.registerEvents()
      this.updateFormattedValue(this.$currencyInput)
    }
  }

  registerEvents() {
    const $input = this.$currencyInput
    $input.on('change input keyup', (event) => {
      $input.tooltip('show')
      const disallowedChars = ['e', 'E', 69, 16]
      const char = event.keyCode || event.which || event.originalEvent.data
      if (char !== undefined && disallowedChars.includes(char)) {
        this.updateTitle($input, this.VALIDITY_MESSAGE)
        return false
      }
      this.updateTitle($input)
    })

    $input.on('blur', ({target}) => {
      $input.tooltip('hide')
      this.updateFormattedValue($input)
    })

    $input.on('focus', ({target}) => {
      if (target.value) {
        this.updateTitle($input)
        $input.tooltip('show')
      }
    })
  }

  updateFormattedValue() {
    const $input = this.$currencyInput
    const value = $input.val()
    const $formattedValue = $input.siblings('.j-formated-value')
    if (value) {
      $formattedValue
        .removeClass('hidden')
        .find('span')
        .html( this.formatCurrency( +value, $input.data( 'currency' ) ) )
    } else {
      $formattedValue.addClass('hidden')
    }
  }

  formatCurrency(value, currencyCode=null) {
    const options = { style: 'currency', useGrouping: true }
    if (currencyCode) {
      options.currency = currencyCode
    }
    const formatter = new Intl.NumberFormat( navigator.language, options )

    if (formatter.formatToParts) {
      return formatter.formatToParts(value).map(({type, value}) => {
        switch (type) {
          case 'currency': '';
          case 'group': return '\u{202F}';
          default: return value;
        }
      }).reduce((string, part) => string + part);
    } else {
      return formatter.format(value)
    }
  }

  updateTitle($input, message=null) {
    if ($input.length == 0 ) { return }
    let showError = false
    let _message = message || $input.val()
    const $inputWrapper = $input.parent()
    const $error = $inputWrapper.find('.j-input-error')

    if (_message) {
      if (!message) {
        _message = this.formatCurrency(_message, $input.data('currency'))
      } else {
        showError = true
      }
      $input.addClass('has-value')
    } else {
      _message = this.VALIDITY_MESSAGE
      showError = true
    }

    if (showError) {
      $inputWrapper.addClass('has-danger')
      $error.html(_message)
    } else {
      $inputWrapper.removeClass('has-danger')
      $error.html(null)
    }

    $input.attr('title', _message)
    $input.attr('data-original-title', _message)
  }

}
