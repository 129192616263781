// Copied from styleguide: https://github.com/Booreiland/inrev-modules/blob/master/src/blocks/b-menu/b-menu.js
import { fold } from './menu_state.js';

'use strict';
$(document).ready(function() {
  const _$body = $('body');
  const _$menu = $('.js-menu');
  const _$menu_wrap = $('.js-menu-wrap');
  const _$fold = $('.js-menu-fold');
  const _$toggle = $('.js-menu-toggle');
  const _$prim_menu = $('.js-primary-menu');
  const _$sec_menu = $('.js-secondary-menu');
  const _$sec_toggle = $('.js-secondary-menu-collapse');
  const _$level_1_toggle = $('.js-sub-level-1-toggle');
  const _$level_1_back = $('.js-sub-level-1-back');
  const _$level_2_toggle = $('.js-sub-level-2-toggle');

  /*Fold menu */

  /*Un-collapse all collapsed menu's */
  const bodyClick = function(e) {
    e.stopPropagation();
    // if the target of the click isn't the container nor a descendant of the container
    if (!_$menu.is(e.target) && (_$menu.has(e.target).length === 0)) {
      // remove body classes
      if (_$body.hasClass('menu-open')) { _$body.removeClass('menu-open'); } else { null; }
      if (_$body.hasClass('sub-menu-open')) { _$body.removeClass('sub-menu-open'); } else { null; }
      if (_$body.hasClass('sec-menu-open')) { _$body.removeClass('sec-menu-open'); } else { null; }
      // Remove classes from toggles & submenu's
      if ($(e.data.toggle).hasClass('is-open')) { $(e.data.toggle).removeClass('is-open'); } else { null; }
      if ($(e.data.toggle).siblings('div.js-sub-level-1').hasClass('is-collapsed')) { $(e.data.toggle).siblings('div.js-sub-level-1').removeClass('is-collapsed'); } else { null; }
      if (_$sec_menu.hasClass('is-collapsed')) { _$sec_menu.removeClass('is-collapsed'); } else { null; }
      // Unbind event
      _$body.unbind('mouseup touchend');
    }
  };

  _$fold.on('click', fold);

  /*Toggle mobile menu */

  _$toggle.on('click', function(e) {
    e.preventDefault();
    if (_$body.hasClass('menu-open')) { _$body.removeClass('menu-open'); } else { _$body.addClass('menu-open'); }
    if (_$toggle.hasClass('is-active')) { _$toggle.removeClass('is-active'); } else { _$toggle.addClass('is-active'); }
    if (_$menu_wrap.hasClass('is-open')) { _$menu_wrap.removeClass('is-open'); } else { _$menu_wrap.addClass('is-open'); }
    if (_$prim_menu.find('.js-sub-level-1-toggle').hasClass('is-open')) { _$prim_menu.find('.js-sub-level-1-toggle').removeClass('is-open'); } else { null; }
    if (_$prim_menu.find('div.js-sub-level-1').hasClass('is-collapsed')) { _$prim_menu.find('div.js-sub-level-1').removeClass('is-collapsed'); } else { null; }
  });

  /*Secondary menu */

  _$sec_toggle.on('click', function(e) {
    e.preventDefault();
    if ($(e.currentTarget).hasClass('is-open')) { _$body.removeClass('sec-menu-open').unbind('mouseup touchend'); } else { _$body.addClass('sec-menu-open').bind('mouseup touchend', { toggle: $(e.currentTarget) }, bodyClick); }
    if ($(e.currentTarget).hasClass('is-open')) { $(e.currentTarget).removeClass('is-open'); } else { $(e.currentTarget).addClass('is-open'); }
    if (_$sec_menu.hasClass('is-collapsed')) { _$sec_menu.removeClass('is-collapsed'); } else { _$sec_menu.addClass('is-collapsed'); }
  });

  /*Level 1 Submenu */

  _$level_1_toggle.on('click', function(e) {
    e.preventDefault();
    const _$level_1_menu = $(e.currentTarget).siblings('div.js-sub-level-1');
    const _$level_2_menu = _$level_1_menu.find('li.has-sub-level-2');
    // Add background overlay
    if ($(e.currentTarget).hasClass('is-open')) { _$body.removeClass('sub-menu-open').unbind('mouseup touchend'); } else { _$body.addClass('sub-menu-open').bind('mouseup touchend', { toggle: $(e.currentTarget) }, bodyClick); }
    // Close all level 1 menus
    const _$level_1_subs = _$prim_menu.children('li.has-sub-level-1').not($(e.currentTarget).closest('li.has-sub-level-1'));
    if (_$level_1_subs.children('.js-sub-level-1-toggle').hasClass('is-open')) { _$level_1_subs.children('.js-sub-level-1-toggle').removeClass('is-open'); } else { null; }
    if (_$level_1_subs.find('.js-sub-level-1').hasClass('is-collapsed')) { _$level_1_subs.find('.js-sub-level-1').removeClass('is-collapsed'); } else { null; }
    // // Close all level 2 menus
    if (_$level_2_menu.children('.js-sub-level-2-toggle').hasClass('is-open')) { _$level_2_menu.children('.js-sub-level-2-toggle').removeClass('is-open'); } else { null; }
    if (_$level_2_menu.find('.js-sub-level-2').hasClass('is-collapsed')) { _$level_2_menu.find('.js-sub-level-2').removeClass('is-collapsed'); } else { null; }
    if ($(e.currentTarget).hasClass('is-open')) { $(e.currentTarget).removeClass('is-open'); } else { $(e.currentTarget).addClass('is-open'); }
    if (_$level_1_menu.hasClass('is-collapsed')) { _$level_1_menu.removeClass('is-collapsed'); } else { _$level_1_menu.addClass('is-collapsed'); }
  });
  // Level 1 Submenu Back button
  _$level_1_back.on('click', function(e) {
    let _$level_1_toggle;
    e.preventDefault();
    const _$level_1_menu = $(e.currentTarget).closest('div.js-sub-level-1');
    _$level_1_toggle = _$level_1_menu.siblings('a.js-sub-level-1-toggle');
    if (_$body.hasClass('sub-menu-open')) { _$body.removeClass('sub-menu-open'); } else { null; }
    if (_$level_1_toggle.hasClass('is-open')) { _$level_1_toggle.removeClass('is-open'); } else { null; }
    if (_$level_1_menu.hasClass('is-collapsed')) { _$level_1_menu.removeClass('is-collapsed'); } else { null; }
  });

  /*Level 2 Submenu */

  _$level_2_toggle.on('click', function(e) {
    e.preventDefault();
    const _$level_1_menu = $(e.currentTarget).closest('div.js-sub-level-1 ul');
    const _$level_2_menu = $(e.currentTarget).siblings('ul.js-sub-level-2');
    // Close other level 2 menus
    const _$level_2_subs = _$level_1_menu.children('li.has-sub-level-2').not($(e.currentTarget).closest('li.has-sub-level-2'));
    if (_$level_2_subs.children('.js-sub-level-2-toggle').hasClass('is-open')) { _$level_2_subs.children('.js-sub-level-2-toggle').removeClass('is-open'); } else { null; }
    if (_$level_2_subs.find('.js-sub-level-2').hasClass('is-collapsed')) { _$level_2_subs.find('.js-sub-level-2').removeClass('is-collapsed'); } else { null; }
    if ($(e.currentTarget).hasClass('is-open')) { $(e.currentTarget).removeClass('is-open'); } else { $(e.currentTarget).addClass('is-open'); }
    if (_$level_2_menu.hasClass('is-collapsed')) { _$level_2_menu.removeClass('is-collapsed'); } else { _$level_2_menu.addClass('is-collapsed'); }
  });
});
