import { h, Component } from 'preact'
import { querySelectorAll } from '../utils'
import MultiSelectWrapper from './MultiSelectWrapper'
import MultiSelectAll from './MultiSelectAll'
import MultiSelectActiveList from './MultiSelectActiveList'

export default class MultiSelectInputContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: this.getSelected(this.props.data),
      data: this.props.data,
      selectingAll: false,
    }
  }

  getSelected(data) {
    const selected = []
    const check = (_data=[]) => {
      return _data.map(d => {
        if (d.checked) selected.push(d)
        if (d.children) {
          check(d.children)
        }
      })
    }
    check(data)
    return selected
  }

  updateSelectionStore(selected) {
    this.props.updateSelected(selected)
  }

  hiddenInputs() {
    const {selected} = this.state
    const { name, allSelected } = this.props
    const input = (val) => <input type='hidden' name={name} value={val} />
    if (selected.length) {
      return this.state.selected.map(s => input(s.value))
    } else {
      return input('')
    }
  }

  checkChildren(parent, checked) {
    parent.children.forEach(child => {
      child.checked = checked
      if (child.children && child.children.length) {
        this.checkChildren(child, checked)
      }
    })
  }

  updateSelected(selected) {
    this.setState({ selected: selected })
  }

  selectionList() {
    const { selected } = this.state
    // Update selection store stored globally in App.MultiSelect.selections
    // with instance id as key
    this.updateSelectionStore(selected)
    return <MultiSelectActiveList
      allSelected={this.props.data.length === selected.length}
      inputId={this.props.id}
      selections={selected} />
  }

  componentDidUpdate() {
    // Trigger change event on first hidden input to trigger submit of form in
    const inputs = querySelectorAll('input', this.hiddenInputContainer)
    if (inputs.length) {
      $(inputs[0]).trigger('change')
    }
  }

  render({id, disabled}, {data, selectingAll, selected}) {
    return (
      <div className='c-multi-select__wrapper'>
        { this.props.showSelectionList && this.selectionList() }
        { this.props.showSelectAll && <MultiSelectAll
          allSelected={data.length === selected.length}
          inputId={id}
          selections={selected} /> }
        <div className='c-multi-select__input-wrapper'>
          <MultiSelectWrapper
            data={data}
            updateSelected={this.updateSelected.bind(this)}
            selectingAll={selectingAll}
            disabled={disabled}
            placeholder={this.props.placeholder}
          />
        </div>
        <div ref={(el) => this.hiddenInputContainer = el}>
          { this.hiddenInputs() }
        </div>
      </div>
    )
  }
}
