let WarningsList;
App.WarningsList = (WarningsList = class WarningsList {
  pending_tabs = ['other_pending', 'critical_pending'];
  filter_form_selector = 'form#j-warnings-filter-form';

  constructor() {
    // Show Warnings content after initial load and updateTabs runs
    $('.js-warnings').removeClass('hidden');

    this.currentTab = window.location.hash.substr(1);

    this.registerEvents();
    this.updateActionButtons();
    this.updateActiveTab();
  }

  // Returns the identifier of the active tab.
  getActiveTab() {
    return `${$('.js-warnings-tabs .is-active').data('tab')}`;
  }

  // Returns the element for a given tab identifier.
  getTab(tab) {
    return $(".js-warnings-tabs").find(`a[data-tab='${tab}']`);
  }

  updateActionButtons() {
    $(".js-warnings-actions a").removeClass('disabled');
    const classToDisable = `.${this.getActiveTab()}-warnings`;
    $(`.js-warnings-actions ${classToDisable}`).addClass('disabled');
  }

  updateTabs($selectedTabEl, selectedTab, event) {
    // event?.preventDefault()
    if ($selectedTabEl) {
      this.currentTab = $selectedTabEl.data('tab');
      $selectedTabEl.siblings().removeClass('is-active');
      $selectedTabEl.addClass('is-active');
    }
    $('.period').hide();
    $('.period-warning').hide();
    $('.period-warning-header').hide();

    $(`.period.tab-${selectedTab}`).show();
    $(`.period-warning.tab-${selectedTab}`).show();
    $(`.period-warning-header.tab-${selectedTab}`).show();

    this.checkCompletion();

    // Hide highlighed rows and the subheader if it has no children
    $('.asset-header').each(function(i, el) {
      const $parentRow = $(el);
      const visibleChildren = $parentRow.nextUntil('.asset-header').map(function(i, el) {
        if ($(el).is(':visible')) { return el; }
      });

      if (visibleChildren.length > 1) {
        $parentRow.show();
      } else {
        $parentRow.hide();
        $(visibleChildren[0]).hide();
      }
    });

    this.updateActionButtons();
    this.removeDuplicateAssetHeaders();
  }

  updateActiveTab() {
    return this.updateTabs(null, this.getActiveTab());
  }

  // Iterate over a period warning row and get all the ids.
  getPeriodWarningIdsFromRow($row) {
    const ids = [];
    $row.next().nextUntil('.asset-header, .period-warning-header').map((i, el) => ids.push($(el).data('id')));
    return ids;
  }

  /*
   * Takes an action button element and determines from the action type
   * (approve or exclude) how to get the ids. For approve, only the
   * warnings in the current tab are included. For exclude, all warnings
   * for the asset (including in other tabs) are included.
   */
  getPeriodWarningIds($link) {
    if ($link.hasClass('js-action-approve')) {
      return this.getPeriodWarningIdsFromRow(this.getParentRow($link))
    } else if ($link.hasClass('js-action-exclude')) {
      const ids = [];
      const warnings = this.getAssetHeader($link).data('all-warnings')
      warnings.map(x => ids.push(x.id));
      return ids;
    }
  }

  removeDuplicateAssetHeaders(headers) {
    // TODO: (Check this in testing) the returns don't look right in this function,
    // but removingthem will change the meaning of the code
    let currentAsset = '';
    return $('.asset-header').each(function(i, header) {
      const $header = $(header);
      const asset = $header.data('asset');
      const visiblePeriodHeaderRows = [];
      $(header).nextUntil('.asset-header').map(function(i, el) {
        const visible = $(el).is(':visible') || !$(el).hasClass('hidden');
        if ($(el).hasClass('period-warning-header') && visible) {
          return visiblePeriodHeaderRows.push(el);
        }
      });
      if ((asset === currentAsset) && (visiblePeriodHeaderRows.length === 0)) {
        $header.addClass('hidden');
        return $header.prev().addClass('b-table__row--fat-border');
      } else {
        currentAsset = asset;
        return $header.prev().addClass('no-border');
      }
    });
  }

  registerEvents() {
    // Blur select inputs on change to revert styling
    $('select.js-input').on('change', function() { $(this).blur(); });

    // Show editiable cells as inputs on click
    $('.js-row-edit').on('click', function() {
      $(this).find('.c-input__wrapper').addClass('active');
      $(this).find('.js-original-val').addClass('hidden');
      $(this).find('.js-edit-val').removeClass('hidden').find('input').focus();
    });

    // POST/PATCH: Bulk Asset
    $('.js-bulk-approve-button').on('click', event => {
      // Because the single-asset approves/excludes are XHR and don't update these ids,
      // We need to: update the link with the pending IDs (the visible warnings in this tab)
      const ids = [];
      $('.period-warning:visible').map((i, el) => { ids.push($(el).data('id')); });

      const $link = $(event.currentTarget);
      const href = $link.data('href');
      const idParams = new URLSearchParams(ids.map(id => ['ids[]', id]))

      $link.attr('href', href + '?' + idParams.toString());
    });

    // XHR: Single asset period: approve/exclude buttons
    $('.js-action-approve').on('click', event => {
      const $link = $(event.target);
      this.handleAction(
        'approved',
        event,
        this.filterWarningsToTab(
          this.getAssetHeader($link).data('all-warnings'),
          this.getPeriodWarningIds($link)
        )
      )
    });
    // XHR: Single asset period: approve/exclude buttons
    $('.js-action-exclude').on('click', event => {
      const $link = $(event.target);
      this.handleAction(
        'excluded',
        event,
        this.getAssetHeader($link).data('all-warnings')
      );
    });

    // Add ids to button, which are then read as a param by bulk_approve/exclude.
    $(document).on('ajax:before', '.js-action-exclude, .js-action-approve', event => {
      const $link = $(event.target);
      const ids = [];
      // This function fetches the correct warning ids for either approve or exclude.
      const warningIds = this.getPeriodWarningIds($link)
      for (const id of warningIds) {
        ids.push(`ids[]=${id}`);
      }
      $link.attr('data-params', ids.join('&'));
    });

    const $filter_form = $(this.filter_form_selector);
    $filter_form.find('select[name="filter[warning]"]').change(function(e) {
      let $selected = $(this).find(":selected");
      $('[name="filter[rule]"]').val($selected.data('rule'));
      $('[name="filter[field]"]').val($selected.data('field'));
      $filter_form.submit();
    })
  }

  // Filter warnings JSON to only warnings shown (not filtered out) on a given tab.
  filterWarningsToTab(warnings, warning_ids) {
    return warnings.filter((warning) => {
      return warning_ids.includes(warning.id)
    })
  }

  // Return the period warning header corresponding to the clicked action button.
  getParentRow($link) {
    return $link.closest('.period-warning-header');
  }

  // Return the asset header (has the warnings JSON data) corresponding to the clicked action button.
  getAssetHeader($link) {
    return this.getParentRow($link).prev('.asset-header')
  }

  // Get the summed count of all warnings in pending tabs.
  getPendingCount() {
    return this.pending_tabs.reduce((a, b) => {
      return this.getTabCount(a) + this.getTabCount(b)
    })
  }

  /* Checks for remaining pending warnings in this tab and overall.
   * Shows the tab or overall completion alert as appropriate. Activates
   * the proceed button if there are no pending warnings left.
   */
  checkCompletion() {
    const currentTab = this.getActiveTab();
    if (this.pending_tabs.includes(currentTab)) {
      // Reset all to hidden, then show what we need.
      $('.js-table-wrapper').addClass('hidden');
      $('.js-all-complete-alert').addClass('hidden');
      $('.js-tab-complete-alert').addClass('hidden');
      if ((this.getPendingCount() === 0)) {
        // If no pending left, can proceed.
        $('.js-all-complete-alert').removeClass('hidden');
        $('.js-proceed').removeClass('disabled');
      } else if ((this.getTabCount(currentTab) === 0)) {
        // If none left in this tab, check the other one.
        $('.js-tab-complete-alert').removeClass('hidden');
      } else {
        $('.js-table-wrapper').removeClass('hidden');
      }
    } else {
      $('.js-table-wrapper').removeClass('hidden');
      $('.js-all-complete-alert').addClass('hidden');
      $('.js-tab-complete-alert').addClass('hidden')
    }
  }

  // Attempt to replicate analogous ruby method defined in formatting_helper.rb
  formattedNumber(number) {
    const thousandsDeliminator = "\u202F"; // Narrow no-break space
    return number.toLocaleString('en').replace(/,/g, thousandsDeliminator);
  }

  addToCount($selector, amountToAdd) {
    const count = $selector.text();
    const newCount = +count.replace(/\s+/g, '') + amountToAdd;
    $selector.text(this.formattedNumber(newCount));
    return newCount;
  }

  /* Takes an array of [PeriodWarning.as_json].
   * as_json is extended to include which tab the warning is in.
   * Adjusts tab counts to remove warnings from the respective pending
   * tabs and add them to the target tab (approved or excluded).
   * Also adjusts bulk button count and intro detail count.
   * Supports an arbitrary number of source tabs, in theory.
   */
  updateCounts(warnings, targetTab) {
    const counts = {}
    warnings.forEach((warning) => {
      let tab = warning.tab
      if (!(tab in counts)) {
        counts[tab] = 0
      }
      counts[tab] += 1
    })
    for (var tab in counts) {
      this.updateTabCount(tab, counts[tab] * -1)
    }
    this.updateTabCount(targetTab, Object.values(counts).reduce((a, b) => a + b))

    // Update count in bulk approve button
    const $bulkApproveCount = $(".js-bulk-approve-button span.count");
    this.addToCount(
      $bulkApproveCount,
      counts[this.getActiveTab()] * -1
    );
  }

  updateTabCount(tab, difference) {
    const $tab = this.getTab(tab);
    const newCount = this.addToCount($tab.find("span.count"), difference);
    if (newCount > 0) {
      $tab.removeClass('disabled');
    }

    // Update count in intro details
    if (this.pending_tabs.includes(tab)) {
      this.addToCount($(".warnings-to-review-count"), difference);
    }
  }

  // Return the currently displayed warning count for a given tab.
  getTabCount(tab) {
    const $tab = this.getTab(tab);
    return Number($tab.find("span.count").text())
  }

  handleAction(action, event, warnings) {
    const data = $(event.target).data();
    const $row = $(event.target).closest('.period-warning-header');
    const currentStatus = data.status;
    const targetStatus = action;
    const currentTab = this.getActiveTab();
    const targetTab = action;

    const classifyElement = (el) => {
      return $(el).removeClass(function(index, className) {
          return (className.match (/(^|\s)(tab|status)-\S+/g) || []).join(' ');
        })
        .addClass(`tab-${targetTab}`)
        .addClass(`status-${targetStatus}`)
    }

    $row.next().nextUntil('.asset-header, .period-warning-header').map(function(i, el) {
      classifyElement(el).hide()
    });

    // Change status of period warnings row - containing action buttons
    classifyElement($row).hide();

    // Update counts in tab bar
    this.updateCounts(warnings, targetTab)

    // Toggle state of action buttons
    $row.find(`.js-action-${currentStatus.slice(0, -1)}`)
      .removeClass('hidden')
      .data('status', action);
    $row.find(`.js-action-${action.slice(0, -1)}`)
      .addClass('hidden')
      .data('status', currentStatus);

    this.updateTabs(this.getTab(currentTab), currentTab);

    const should_reload = $('.js-data-row:visible').length === 0 && this.getTabCount(currentTab) > 0;
    const $filter_form = $(this.filter_form_selector);
    if ($filter_form && should_reload) {
      // There are filtered out warnings on this tab.
      // Resubmitting the form with reset the filters for this tab.
      $filter_form.submit();
    } else {
      setTimeout(() => {
          this.checkCompletion();
        },
        500
      );
    }
  }
});
