import _ from 'lodash'
import { xhrResponseURL } from '../utils'
import { handleFilterToggle } from './toggle'

// handle auto-submit and conditional fields within
// a search filter form.. This is a cut-down
// version of AnalysisFilters for use in simpler
// cases. FIXME: It would be nice if that could use this
// in some future world to reduce logic duplication
export default class FilterForm {
  constructor (props) {
    this.formEl = props.formEl // jquery selector
    this.resultsEl = props.resultsEl
    this.$form = $(this.formEl)
    // delay time to debounce form field changes
    // mostly because of the sliders
    this.debounceTime = props.debounceTime || 250
    this.init()
  }

  init () {
    // console.log('FilterForm', 'init', this.formEl)
    this.registerEvents()
  }

  tagRequestSequence (xhr) {
    if (typeof(self.ajaxRequestSequence) == 'undefined') {
      self.ajaxRequestSequence = 0;
    }
    if (typeof(self.lastXhr) != 'undefined') {
      // console.log('ajax','cancel', { requestSequence: self.ajaxRequestSequence})
      self.lastXhr.abort()
    }
    self.lastXhr = xhr;
    self.ajaxRequestSequence = self.ajaxRequestSequence + 1;
    xhr.requestSequence = self.ajaxRequestSequence;
    console.debug('ajax','send', { requestSequence: xhr.requestSequence})
  }

  requestSequenceCorrect (xhr) {
    if (xhr.requestSequence == self.ajaxRequestSequence) {
      console.debug('ajax','receive', { requestSequence: xhr.requestSequence})
      return true
    } else {
      console.debug('ajax','receive', { requestSequence: xhr.requestSequence, outOfOrder: true})
      return false
    }
  }

  /**
   * registerEvents
   *
   */
  registerEvents () {
    const self = this
    const ajaxSelector = this.formEl;

    // EVENT ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Respond to change events on all inputs, selects
    // and submit the form
    $(document).on('change',
      ajaxSelector,
      _.debounce(self.submitFilters.bind(self), self.debounceTime)
    )

    // EVENT ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Handle successful form submit
    $(document).on('ajax:send', ajaxSelector, (event) => {
      const xhr = event.detail[0];
      self.tagRequestSequence(xhr);
    })

    $(document).on('ajax:success', ajaxSelector, (event) => {
      const detail = event.detail;
      const responseText = detail[0], status = detail[1], xhr = detail[2];
      if (!self.requestSequenceCorrect(xhr)) {
        return;
      }
      const partial = $(responseText).find(self.resultsEl).html()
      $(self.resultsEl).html(partial);
      const responseURL = xhrResponseURL(xhr)
      if (responseURL) {
        window.history.replaceState(null, '', responseURL);
      }
    })

    // EVENT ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Collapse/expand filter UI
    $(document).on('click', '.j-filter-toggle', handleFilterToggle)
  }

  /**
   * submitFilters
   * Use Rails UJS fire method to trigger form submit
   */
  submitFilters () {
    console.debug('Form#submit', this.formEl, this.resultsEl)
    Rails.fire(this.$form[0], 'submit')
  }
}
