import BaseChart from './_base';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import { extend } from 'lodash';
import {
  highchartsNavigation,
  highchartsTooltipOptions,
  highchartsDownloadContextButton,
  highchartsLegend
} from './highcharts_options';

Exporting(Highcharts);
ExportData(Highcharts);
OfflineExporting(Highcharts);
export default class LineMultiAxis extends BaseChart {
  constructor (container, props) {
    super(container, props);
    this.data = props.data;
    this.options = extend({
      labelRotation: -45,
      showContextButton: false,
      showTitle: true,
      showGridLines: false,
      showAxisLines: false,
      animation: true,
      showLegend: true,
      showPoints: false,
      enableMouseTracking: true,
      margin: {},
      dimensions: {},
      legend: {},
      colors: []
    }, props.options);
    this.init();
    return this.chart;
  }

  renderChart () {
    const theme = window.App.theme;
    const _options = this.options;
    const labelStyle = {
      color: theme.colors.defaults.chart.labelsBright,
      fontSize: '12px',
      fontFamily: 'Arial'
    };
    const axisStyle = {
      allowDecimals: false,
      minorGridLineColor: 'transparent',
      minorGridLineWidth: 0,
      tickPixelInterval: 30,
      minorTickLength: 0,
      minPadding: 0,
      maxPadding: 0,
      tickLength: 0
    };
    const yAxis = [];
    this.data.yAxis.forEach(function (axis) {
      yAxis.push(
        extend({
          title: {
            style: {
              color: theme.colors.defaults.chart.labelsBright,
              fontFamily: 'Arial',
              margin: 20
            },
            margin: 0
          },
          labels: {
            style: labelStyle,
            formatter: function () {
              if (axis.format_as === 'in_millions') {
                return this.value / 1000000 + 'M';
              } else if (axis.format_as === 'percent') {
                return this.value + '%';
              } else {
                return this.value;
              }
            }
          },
          lineColor: axis && axis.color ? axis.color : 'transparent',
          lineWidth: axis && axis.showAxisLines ? 1 : 0,
          gridLineColor: axis && axis.showGridLines ? '#E2E2E2' : 'transparent',
          gridLineWidth: axis && axis.showGridLines ? 1 : 0
        }, axisStyle, axis)
      );
    });
    const chartOptions = {
      exporting: {
        buttons: {
          contextButton: highchartsDownloadContextButton(_options.showContextButton)
        },
        fallbackToExportServer: false,
        url: 'disabled'
      },
      chart: {
        type: 'line',
        style: {
          fontFamily: '"Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif',
          color: theme.colors.defaults.chart.labelsDim
        },
        height: _options.dimensions.height || this.container.clientHeight || null,
        width: _options.dimensions.width || this.container.clientWidth || null,
        marginTop: _options.margin.top,
        marginBottom: _options.margin.bottom,
        marginLeft: _options.margin.left,
        marginRight: _options.margin.right
      },
      title: { text: _options.showTitle ? (this.data.title.text || this.props.title) : null },
      tooltip: extend({
        pointFormat: '<span class="d-block"><span class="d-inline" style="color:{series.color}">{series.name}:</span> <b>{point.y}</b></span>'
      }, highchartsTooltipOptions()),
      plotOptions: {
        column: {
          groupPadding: 0.1,
          borderWidth: 0,
          groupZPadding: 0
        },
        series: {
          animation: _options.animation,
          enableMouseTracking: _options.enableMouseTracking,
          marker: { enabled: _options.showPoints }
        },
        line: {
          lineWidth: 3,
          marker: _options.showPoints
            ? extend({ radius: 5 }, _options.marker)
            : { enabled: false }
        }
      },
      colors: _options.colors.length ? _options.colors.length : this.data.colors,
      xAxis: extend({
        title: {
          text: _options.xAxis && _options.xAxis.title ? _options.xAxis.title : '',
          style: {
            color: theme.colors.defaults.chart.labelsBright,
            fontFamily: 'Arial'
          },
          margin: 0
        },
        labels: {
          style: labelStyle,
          rotation: _options.labelRotation
        },
        categories: this.data.xAxis.categories,
        tickColor: '#FFFFFF',
        lineColor: _options.xAxis && _options.xAxis.color ? _options.xAxis.color : 'transparent',
        lineWidth: _options.xAxis && _options.xAxis.showAxisLines ? 1 : 0,
        gridLineColor: _options.xAxis && _options.xAxis.showGridLines ? '#E2E2E2' : 'transparent',
        gridLineWidth: _options.xAxis && _options.xAxis.showGridLines ? 1 : 0
      }, axisStyle),
      yAxis: yAxis,
      credits: {
        enabled: false
      },
      series: this.data.series,
      legend: extend(
        highchartsLegend(_options.legend), {
          useHTML: true,
          labelFormatter: function () {
            let legendIcon;
            if (this.visible) {
              legendIcon = ' <i class="icon--sm icon-inrev-legend-show" />';
            } else {
              legendIcon = ' <i class="icon--sm icon-inrev-legend-hide" />';
            }
            return this.name + legendIcon;
          }
        }),
      navigation: highchartsNavigation(),
      events: {
        render: () => {
          this.updatePoints();
        }
      }
    };
    this.chart = Highcharts.chart(this.container, chartOptions);
    return this.chart;
  }
}
