export function initBtnGroups() {
  // event target is label which contains input
  // Extend keyboard interaction with customozed button group
  // select input when user presses enter (13) or space (32)
  // Then refocus label
  $('.b-button-group').on('keypress', (event) => {
    if (event.which == 13 || event.which == 32) {
      const $target = $(event.target)
      $target.find('input').trigger('click')
      $target.find('input').trigger('change')
      $target.focus()
    }
  })
}
