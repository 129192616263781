import { createEvent } from './utils'

export function fold (event) {
  const _$body = $('body')
  const _$menu = $('.js-menu')
  if (event)
    event.preventDefault()

  if (_$body.hasClass('menu-folded')) {
    document.cookie = 'menu_state=0'
    _$body.removeClass('menu-folded')
    _$menu.removeClass('is-folded')
  } else {
    document.cookie = 'menu_state=1'
    _$body.addClass('menu-folded')
    _$menu.addClass('is-folded')
  }
  window.dispatchEvent(createEvent('resize'))
}
