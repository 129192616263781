export function highchartsNavigation () {
  return {
    buttonOptions: {
      verticalAlign: 'bottom',
      y: -10,
      theme: {
        'stroke-width': 1,
        stroke: '#E1E1E1',
        r: 3,
        padding: 12,
        states: {
          hover: {
            fill: '#FFFFFF'
          },
          select: {
            fill: '#FFFFFF'
          }
        }
      }
    }
  };
}

export function highchartsLegend (enabled = true, alignment = 'center', useHTML = true, cords = { x: 0, y: 0 }, symbolWidth = 16) {
  const theme = window.App.theme;
  return {
    enabled: enabled,
    layout: 'horizontal',
    align: alignment,
    useHTML: useHTML,
    itemStyle: {
      fontSize: '13px',
      fontFamily: 'Arial',
      color: theme.colors.defaults.chart.labelsDim
    },
    x: cords.x,
    y: cords.y,
    symbolWidth: symbolWidth
  };
}

export function highchartsDownloadContextButton (enabled = true, menuItems = ['downloadPDF', 'downloadXLS']) {
  return {
    menuItems: menuItems,
    text: 'Print / Save',
    symbol: '',
    enabled: enabled
  };
}

export function highchartsTooltipOptions (enabled = true) {
  return {
    enabled: enabled,
    shared: true,
    useHTML: true,
    shadow: false
  };
}
