import { h, Component } from 'preact'
import { deepCopy } from '../utils'
import classnames from 'classnames'

export default class MultiSelectActiveList extends Component {

  constructor(props) {
    super(props)
    this.SELECTED_SHOW_LIMIT = 5
  }

  atEndOfList(i, selected=this.props.selections) {
    return i+1 === selected.length
  }

  getInputInstance() {
    return App.MultiSelect.instances[this.props.inputId]
  }

  handleRemove(item, event) {
    event.preventDefault()
    this.getInputInstance().removeSelection(item)
  }

  items() {
    const { selections } = this.props
    let _selected = deepCopy(selections)
    let selectionsPresented = []
    let moreItemsThanLimit = false
    if (_selected.length) {
      if (selections.length > this.SELECTED_SHOW_LIMIT) {
        selectionsPresented = _selected.slice(0, this.SELECTED_SHOW_LIMIT)
        moreItemsThanLimit = true
        selectionsPresented.push({label: `(${selections.length - this.SELECTED_SHOW_LIMIT} more items)`})
      } else {
        selectionsPresented = _selected
      }
    }
    return selectionsPresented.map((s,i) => {
      const overflowing = moreItemsThanLimit && this.atEndOfList(i, selectionsPresented)
      let clearLink = (
        <a href='#' className='c-multi-select__selected-link' onClick={this.handleRemove.bind(this, s)} >
          <i className='icon-inrev icon-inrev-close' />
        </a>
      )
      if (overflowing && this.atEndOfList(i, selectionsPresented)) {
        clearLink = null
      }
      const className = classnames(
        'c-multi-select__selected-item',
        {'font-italic': overflowing}
      )
      return (
        <li className={className} key={i} >
          { clearLink }
          { s.label }
          { this.atEndOfList(i, selectionsPresented) ? '' : ';' }
        </li>
      )
    })
  }

  render({selections, allSelected}) {
    const selectAllClassNames = classnames(
      'c-multi-select__selected-link',
      {'d-none': allSelected}
    )
    const removeAllClassNames = classnames(
      'c-multi-select__selected-link',
      {'d-none': !selections.length}
    )
    return (
      <ul className='c-multi-select__selected-list'>
        { this.items() }
      </ul>
    )
  }
}
