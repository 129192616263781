App = App || {}
App.Forms = {}
App.Events = {}

import Phone from './phone'
App.Forms.Phone = Phone

import CurrencyInput from './currency'
App.Forms.CurrencyInput = CurrencyInput

import Select2Input from '../select2'
App.Forms.Select2Input = Select2Input

import DatePickerInput from './date_picker'
App.Forms.DatePickerInput = DatePickerInput

import ValueSummary from './value_summary'
App.Forms.ValueSummary = ValueSummary

import WatchForSuccess from './watch_for_success'
App.Forms.WatchForSuccess = WatchForSuccess

import { initValidation, Validate } from './validation'
App.Forms.Validate = Validate

import { initLabels } from './labels'
import { initOtherOption, initOtherOptionForElement } from './other_option'
import { initDependentOption } from './dependent_option'
import { initBtnGroups } from './btn_group'
import { initHints } from './hint'
import { initTextareas } from './textareas'
App.Events.initOtherOptionForElement = initOtherOptionForElement

$(() => {
 initLabels()
 initOtherOption()
 initDependentOption()
 initValidation()
 initBtnGroups()
 initHints()
 initTextareas()
})
