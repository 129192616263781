const validatedInputs = 'input:not([type=hidden]), select, textarea'

const getInputContainer = ($input) => {
  switch ($input.attr('type')) {
    case 'radio':
      return $input.closest('radiogroup')
    case 'checkbox':
      // return $input.closest('.text-array__row')
      return $input.closest('.b-collection')
    default:
      return $input
  }
}

export function initValidation() {
  const checkForVal = (input) => {
    let action = 'removeClass'
    let showValidation = ''
    const $input = $(input)

    if ($input.attr('type') === 'radio') {
      if ($input.is(':valid')) {
        getInputContainer($input).siblings('.b-form__feedback').hide()
        $input.closest('.b-form__group').removeClass('has-danger')
      }
    }

    if ($input.attr('type') === 'checkbox') {
      // for checkboxes where at least one item must be checked
      if ($input.is('.required') && $input.closest('.b-collection').find('input[type=checkbox]:checked').length == 0) {
        getInputContainer($input).siblings('.b-form__feedback').show()
        $input.closest('.b-form__group').addClass('has-danger')
      } else {
        getInputContainer($input).siblings('.b-form__feedback').hide()
        $input.closest('.b-form__group').removeClass('has-danger')
      }
    }

    if ($input.val()) {
      action = 'addClass'
      // If input has value on load, force show of valid state
      if (!$input.hasClass('dirty')) {
        showValidation += 'show-validation validate'
      }
    }
    $input[action](`has-value ${showValidation}`)
    // If input value was removed, do not force show of valid state
    if (!showValidation) {
      $input.removeClass('show-validation')
    }
  }

  // Excluding "b-form--validated", as has-value already does this as part of it's flow.
  $('.b-form--floating-labels:not([class=b-form--validated])')
    .find(validatedInputs)
    .on('change input', function(event) {
        const $input = $(this)
        if ($input.val()) {
          $input.addClass('has-value');
        } else {
          $input.removeClass('has-value');
        }
    })


  $('.b-form--validated')
    .find(validatedInputs)
    .each((i, el) => {
      const $input = $(el)
      // On submit, the server response may add validation feedback to inputs.
      // If so, the inputs will be followed by an element with a class name
      // of `.b-form__feedback`
      // If the input has this sibling, it also needs a class name of `validate`
      // in order for the native HTML validation / CSS to update the
      // validity-related style and hide `.b-form__feedback` based on it's
      // validity
      const $inputContainer = getInputContainer($input)

      if ($inputContainer.siblings('.b-form__feedback').length) {
        $input.addClass('validate')
      }
      checkForVal(el)
      // Add 'has-value' class on value change
      $input.on('change input', ({target}) => checkForVal(target))
      // Set dirty state on blur
      $input.one('blur input change', ({target}) => $(target).addClass('is-dirty'))
      // Check for Server side validation message
      // Add if not present for consistency
      if (!$input.siblings('.b-form__feedback--on-load').length) {
        $input.closest('.b-form__group').append(`
          <p class="b-form__feedback b-form__feedback--on-load b-form__helper">
          </p>
        `)
      }
    })
}

export class Validate {
  constructor(props) {
    this.props = props
    this.errors = {}
    this.$form = $(`#${props.formId}`)
    this.inputs = new Set()
    this.debounceValidate = _.debounce(this._validate.bind(this), 500)

    this.registerEvents()
    this.firstValidationRun = false;

    // Trigger validation
    this.$form.find(':input:visible:first').blur();

  }

  registerEvents() {
    this.$form.on(
      'select2:close blur',
      'select, input, select ~ .select2, textarea',
      this.validate.bind(this)
    )
  }

  validate(event) {
    this.inputs.add($(event.target).attr('id'))
    this.debounceValidate(event)
  }

  _validate(event) {
    const { formId, endpoint } = this.props
    const form = document.getElementById(formId)
    $.ajax({
      type: "POST",
      url: endpoint,
      data: $(form).serialize(),
    }).done((data) => {
      this.errors = data


      console.debug("Inputs since last debounce:", this.inputs.size, this.inputs)
      for (var input of this.inputs) {
        this.updateInputMessage($(`#${input}`))
      }
      this.updateSideBarMessages()
      this.updateInputMessages()
      this.inputs = new Set()
    }).fail(({status}, error) => {
      if (status === 422) {
        $('#ajax-modal .modal-body')
          .addClass('d-flex align-items-center flex-column')
          .html(`
            <span class='icon--xl icon-inrev icon-inrev-pending mb-4' />
            <p class="b-modal__text mb-4 text-center">
              Your session has expired.<br />
              Please sign in to continue editing this field.
            </p>
            <a href='/' class='c-button mb-4'>Sign in</a>
          `)
        $('#ajax-modal').modal('show')
      } else {
        console.debug(`Issue validating fields. Error: ${error}, Status: ${status}`)
      }
    })
  }

  getMessageElement($input) {
    return $input.siblings('.b-form__feedback--on-load')
  }

  getInputValidationData(inputName) {
    let data = {}

    // Get attribute name from input name (the last bracketed string)
    const attribute = inputName.match(/[^\[\]]+/g).last()
    const validationKey = $(':input[name="' + inputName.replace('[' + attribute + ']', '[validation_key]') + '"]').val();
    let errorKey = inputName;

    if (validationKey) {
      // Dom format: vehicles_period[corporate_actions_attributes][0][date]
      // Validation API format: vehicles_period[corporate_actions_attributes][40361][date]
      errorKey = inputName.replace(/\[\d+\]/, '[' + validationKey + ']')
    }

    if (this.errors && errorKey in this.errors) {
      data = this.errors[errorKey]
    }

    return data
  }

  updateInputMessage($input, validationData) {
    const $messageEl = this.getMessageElement($input);
    const inputName = $input.attr('name');

    // There's an issue with select2s with an other input --
    // when the other input is focused, it triggers validation and
    // for some reason the active input has no name
    // so we make this a no-op if inputName is null or undefined
    if (!inputName) return;

    if (!validationData) {
      validationData = this.getInputValidationData(inputName);
    }
    const { error } = validationData;
    const isValid = (error && error.length) ? false : true;
    const validClass = isValid ? '' : ' validate';

    $input.addClass('is-dirty' + validClass).attr('valid', isValid);
    $messageEl.html(this.buildErrorSting(error));
  }

  inputNameFromErrorKey(errorKey) {
    let inputName = null
    let matches = null

    if (matches = errorKey.match(/^(.*?\[)(validate_[^\]]*)(\].*?)$/)) {
      const $validationInput = this.$form.find(':input[value="' + matches[2] + '"]')

      if ($validationInput.length) {
        const $siblings = $validationInput.first().parents('tr').find(':input')
        $siblings.each(function(i, input) {
          const $input = $(input)
          const namePattern = (matches[1] + '.*?' + matches[3]).replace(/([\[\]])/g, '\\$1')
          if ($input.attr('name').match(new RegExp(namePattern, "g"))) {
            inputName = $input.attr('name')
          }
        })
      }
    } else {
      inputName = errorKey
    }

    return inputName
  }

  buildErrorSting(error) {
    let errorText = error
    // If the error is an array, build out the string accordingly
    if (typeof error === 'object') {
      errorText = error.join('; ')
    }
    return errorText
  }

  updateInputMessages() {
    if (!this.firstValidationRun) {
      if (typeof this.errors === 'object' && this.errors !== null) {
        for (const errorKey in this.errors) {
          const validationData = this.errors[errorKey]
          const attribute = errorKey.match(/[^\[\]]+/g).last()
          const inputName = this.inputNameFromErrorKey(errorKey)

          if (inputName) {
            const $input = this.$form.find(':input[name="' + inputName + '"]')
            if ($input.length) {
              this.updateInputMessage($input.first(), validationData)
            }
          }
        }

        // First validation (page load), show all validation input errors.
        // After that, rely on links from sidebar
        this.firstValidationRun = true
      }
    }
  }

  updateSideBarMessages() {
    if (typeof this.errors === 'object' && this.errors !== null) {
      let errorCount = 0
      let warningCount = 0

      for (const errorKey in this.errors) {
        if (this.errors[errorKey].type == 'error') {
          errorCount += 1
        } else if (this.errors[errorKey].type == 'warning') {
          warningCount += 1
        }
      }

      // Remove content / body of tab panels.
      $(this.sideBarContentSelector()).find('.j-errors-list .c-validation-list').remove();
      $(this.sideBarContentSelector()).find('.j-warnings-list .c-validation-list').remove();

      // Tab status
      $('.j-errors-tab').text(errorCount >= 0 ? '(' + errorCount + ')' : '')
      $('.j-warnings-tab').text(warningCount >= 0 ? '(' + warningCount + ')' : '')
      if (errorCount > 0 && warningCount == 0) {
        $('.j-sidebar-link').removeClass('is-active');
        $('.j-errors-link').addClass('is-active');

        $('.j-sidebar-list').addClass('d-none');
        $('.j-errors-list').removeClass('d-none')
      } else if (errorCount == 0 && warningCount > 0) {
        $('.j-sidebar-link').removeClass('is-active');
        $('.j-warnings-link').addClass('is-active');

        $('.j-sidebar-list').addClass('d-none');
        $('.j-warnings-list').removeClass('d-none')
      }

      // Show hide error warning label (inside errors tab panel)
      if (errorCount > 0) {
        $('.j-errors-list .b-aside__lists-errors').removeClass('d-none')
      } else {
        $('.j-errors-list .b-aside__lists-errors').addClass('d-none')
      }
      if (warningCount > 0) {
        $('.j-warnings-list .b-aside__lists-warning').removeClass('d-none')
      } else {
        $('.j-warnings-list .b-aside__lists-warning').addClass('d-none')
      }

      for (const errorKey in this.errors) {
        this.updateSideBarMessage(errorKey, this.errors[errorKey])
      }
    }
  }

  focusInputFunction(inputName) {
    return function () {
      const $input = $(':input[name="' + inputName + '"]').filter(':visible')
      if ($input.length) {
        $input.first().focus()
      }
    }
  }

  sideBarContentSelector() {
    return this.props.asideSelector || '.b-aside__lists'
  }

  updateSideBarMessage(errorKey, validationData) {
    const { error, label, sections={}, value, values, type } = validationData;
    const sectionNames = Object.values(sections);
    const sectionIds = Object.keys(sections);
    const inputName = this.inputNameFromErrorKey(errorKey);
    let isError = type == 'error';
    let $asideContent = $(this.sideBarContentSelector()).find(isError ? '.j-errors-list' : '.j-warnings-list');
    let $asideMessageEl = $asideContent.find(`[data-attribute='${errorKey}']`);
    let sectionName = sectionNames[0];
    let sectionId = sectionIds[0];
    let icon = `icon-inrev-warning-${type === 'error' ? 'circle' : 'triangle'}`
    if (error && sectionName && sectionId) {
      let description = this.buildErrorSting(error);
      let $sectionEl = $asideContent.find(`[data-section='${sectionId}']`);
      if (!$sectionEl.length) {
        $sectionEl = $(`
          <div class='c-validation-list' data-section='${sectionId}'>
            <div class='c-validation-list__section'>
              <div class='c-validation-list__section-title c-validation-list__section-title--${type}'>
                <h4 class=''>${sectionName}</h4>
              </div>
              <div class='c-validation-list__section-content'></div>
            </div>
          </div>
        `);
        $asideContent.append($sectionEl);
      }

      $asideMessageEl = $(`
        <div class='c-validation-list__item' data-attribute='${errorKey}'>
          <div class='c-validation-list__title c-validation-list__title--${type}'>
            <h4>${label}</h4>
            <i class='icon ${icon}'></i>
          </div>
          <div class='row m-2'>
            <div class='col'>
              <div><i>${description.charAt(0).toUpperCase() + description.slice(1)}</i></div>
            </div>
          </div>
        </div>
      `).append(this.templateSidebarRowValuesHtml(inputName, value, values));
      $sectionEl.find('.c-validation-list__section-content').append($asideMessageEl);
    }
  }

  templateSidebarRowValuesHtml(inputName, value, values) {
    let $valuesRow = $(`<div class="row m-2"></div>`);
    let valuesToShow = [];
    if (values) {
      valuesToShow = values;
    } else {
      valuesToShow = [
        {
          value: value,
          label: 'Current value',
          form_key: inputName,
        }
      ];
    }
    valuesToShow.forEach((tmpValue) => {
      let editableLink = '';
      if (tmpValue.form_key) {
        editableLink = `
          <a href='javascript:'>
            <b>${tmpValue.value || '(blank)'}</b>
            <i class='icon--sm mr-4 icon-inrev-pencil'></i>
          </a>
        `;
      } else {
        editableLink = `
          <div>
            <b>${tmpValue.value || '(blank)'}</b>
          </div>
        `
      }
      let $value = $(`
        <div class='col'>
          <div><i>${tmpValue.label}:</i></div>
          ${editableLink}
        </div>
      `)
      if (tmpValue.form_key) {
        $value.on({'click': this.focusInputFunction(tmpValue.form_key)})
      }

      $valuesRow.append($value);
    });

    return $valuesRow;
  }

}
