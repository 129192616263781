import intlTelInput from 'intl-tel-input'

export default class Phone {
  constructor() {
    this.ERRORS = [
      "Invalid number",
      "Invalid country code",
      "Number is too short",
      "Number is too long",
      "Invalid number",
    ];
  }

  initDisplay() {
    // TODO: We need to figure out how to call the functions or methods in
    //       the untilities script
    // $(() => {
    //   $('.j-tel-display').each((i, numberEl) => {
    //     const number = numberEl.innerHTML
    //     if (!number.length || number.includes('blank')) {
    //       return
    //     }
    //   })
    // })
  }

  initInputs() {
    const self = this
    $(() => {
      const options = {
        separateDialCode: true,
        autoHideDialCode: false,
        nationalMode: true,
        geoIpLookup: (success, failure) => {
          $.get("https://ipinfo.io", () => {}, "jsonp")
            .always((resp) => {
              const countryCode = (resp && resp.country) ? resp.country : "";
              success(countryCode);
            })
        },
      }

      // Move label from outside of intTel wrapper to within so
      // default input behavior based on adjacency works
      $('input[type="tel"]').each((i, input) => {
        const _options = Object.assign({
          hiddenInput: null
        }, options)

        // Set <input data-initial-country="auto"> to enable the geoIpLookup() above.
        _options.initialCountry = $(input).data().initialCountry || ''

        const instance = intlTelInput(input, _options);
        const $input = $(input)
        const $telContainer = $input.parent()
        const $relatedEls = $telContainer.nextAll().detach()
        $telContainer.append($relatedEls)

        // Add validation-related classes so validation happens on first focus
        // and thereafter
        input.classList.add('validate', 'is-dirty')

        // Create string of number with ext if one exists
        const formatNumber = () => {
          const ext = instance.getExtension()
          return instance.getNumber() + (ext ? ` ext. ${ext}` : '')
        }

        const $hiddenInput = $(`<input type='hidden' name='${input.name}' value='${formatNumber()}' ></input>`)
        $hiddenInput.insertAfter($input)

        instance.setNumber(formatNumber())

        // Prevent overlapping elements
        const updateZIndex = ({type}) => {
          let zIndex = 1000
          if (type.includes('close')) {
            zIndex = 100
          }
          $input.closest('.b-form__group').css('z-index', zIndex)
        }
        $input.on('open:countrydropdown', updateZIndex )
        $input.on('close:countrydropdown', updateZIndex )

        const validate = (event=null) => {
          const hiddenInput = input.nextElementSibling
          if (input.value.trim()) {
            const $helperLabel = $(input).siblings('.j-input-error')
            if (instance.isValidNumber()) {
              const val = formatNumber()
              hiddenInput.value = val
              // Change valid attr to true to change appearance
              input.setAttribute('valid', 'true')
              // Remove content from input's error helper text
              $helperLabel.html('')
              if (event && event.type === 'blur') {
                instance.setNumber(val)
              }
            } else {
              // Change valid attr to false to change appearance
              input.setAttribute('valid', 'false')
              // Update error message
              $helperLabel
                .html(self.ERRORS[instance.getValidationError()])
            }
          } else {
            input.removeAttribute('valid')

            if (hiddenInput) {
              hiddenInput.value = ''
            }
          }
        }

        // Update validity state on input change & input and when the country
        // changes
        ;['change', 'input', 'countrychange', 'blur']
          .forEach(event => {
            input.addEventListener(event, validate)
          })

        validate()
      })
    })
  }
}
