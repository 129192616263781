import { handleFilterToggle } from './filters/toggle';

$(function() {
  // FIXME: replace with App.Forms.DatePicker
  // the pikaday initializer triggers an ajax request
  // we need to abort the request until after the
  // picker is done initializing
  $(document).on('ajax:before', '.pikaday', function(e) {
    if ($(e.target).hasClass('initialized')) {
      console.log('running pikaday xhr request');
    } else {
      console.log('aborting pikaday xhr request');
      return false;
    }
  });

  const datepickers = $('.pikaday');

  // initialize all of the date pickers on the page.
  datepickers.each(function(_, picker) {
    var maxDate, minDate;
    if ($(picker).attr('min')) { minDate = moment($(picker).attr('min')).toDate(); }
    if ($(picker).attr('max')) { maxDate = moment($(picker).attr('max')).toDate(); }

    new Pikaday({
      field: picker,
      format: 'DD/MM/YYYY',
      minDate,
      maxDate,
      defaultDate: maxDate,
      onClose() {
        $('.c-input__wrapper').removeClass('active');
      }
    });
    $(picker).addClass('initialized');
  })

  // if you uncheck a remote checkbox send 'off'
  $(document).on('ajax:before', 'input[type="checkbox"][data-remote="true"]', function(e) {
    const checkbox = $(e.target).closest('input');
    if (!checkbox.is(':checked')) {
      const name = checkbox.prop('name');
      checkbox.attr('data-params', `${name}=off`);
    }
  });

  // Init tooltips
  $('[data-toggle="tooltip"]').tooltip({ boundary: 'window', container: 'body' });
  $('[data-toggle="tooltip"] .c-switch__input').on('change', function () {
    const $label = $(this).closest('label');
    const tooltipClass = $label.data('tooltip-class');
    let title = '';
    let titlesFound = false;

    if (this.checked) {
      title = $label.data('yes-title');
      titlesFound = title || $label.data('no-title');
    } else {
      title = $label.attr('data-no-title');
      titlesFound = title || $label.data('yes-title');
    }

    if (titlesFound) {
      $label.attr('data-original-title', title);
    }

    if (tooltipClass) {
      const $switch = $(this).closest('.c-switch');
      title ? $switch.addClass(tooltipClass) : $switch.removeClass(tooltipClass);
      $(this).blur();
    }

    $label.tooltip('show');
  });

  // Handle scrollbar bug in Edge browser
  // we must add padding otherwise the toggle icons are overlappedd by the bar
  if (navigator.userAgent.includes("Edge")) {
    $('.b-menu__wrap').addClass('b-menu__wrap--padded');
  }

  $('.j-aside-toggle').on('click', handleFilterToggle);

  $('.j-smooth-scroll-link').each((i, link) => {
    $(link).on('click', (event) => {
      const $linkContent = $(link.hash)
      if ($linkContent.length) {
        event.preventDefault()
        event.currentTarget.blur()
        $('.js-main-scroll').animate({
          scrollTop: $linkContent.position().top
        }, 500);
      }
    })
  })

});
