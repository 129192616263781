// add toggle support to for data-remote checkboxes
// see https://github.com/rails/jquery-ujs/issues/440
jQuery(document)
  .off('ajax:before.remote-checkbox')
  .on('ajax:before.remote-checkbox', 'input[type=checkbox][data-remote]', function() {
    var $this  = $(this);

    // Ensure value is a 1 or 0
    var value = this.checked * this.checked;

    // Append value to current params
    var currentParams = $this.data('params');
    var params = (
      (currentParams ? currentParams + '&' : '') +
      $this.attr('name') + '=' + value
    );

    $this.attr('data-params', params).attr('disabled', true);
  })
  .on('ajax:complete', function(event) {
    $(event.target).removeAttr('disabled');
  }
);