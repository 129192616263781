import { h, Component } from 'preact'
import { querySelectorAll } from '../utils'
import DropdownTreeSelect from 'react-dropdown-tree-select'

// Polyfill for ie11
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
if (!String.prototype.startsWith) {
  Object.defineProperty(String.prototype, 'startsWith', {
    value: function(search, rawPos) {
      var pos = rawPos > 0 ? rawPos|0 : 0;
      return this.substring(pos, pos + search.length) === search;
    }
  });
}

export default class MultiSelectWrapper extends Component {
  constructor(props) {
    super(props)
  }

  onChange(currentNode, selectedNodes) {
    this.props.updateSelected(selectedNodes)
    // Remove names component applies to prevent submit issues
    querySelectorAll('input', this.wrapper)
      .forEach(i => i.removeAttribute('name'))
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { data } = this.props
    return false
  }

  render({data}, {}) {
    return (
      <div ref={(el) => this.wrapper = el}>
        <DropdownTreeSelect
          data={data}
          className='c-multi-select__container'
          onChange={ this.onChange.bind(this) }
          keepTreeOnSearch={ true }
          showPartiallySelected={ true }
          texts={{placeholder: this.props.placeholder}}
          disabled={this.props.disabled}
        />
      </div>
    )
  }
}
