export default class WatchForSuccess {
  constructor({className, callback}) {
    this.className = className
    this.callbackFn = callback
    this.registerEvents()
  }

  registerEvents() {
    const self = this
    $(`.${this.className}`).on('ajax:success', function(event, xhr, status, error) {
      self.callback(this, event, xhr, status, error)
    })
    .on('ajax:error', (event, xhr, status, error) => {
      throw 'Error updating permissions'
    })
  }

  callback(form, event, xhr, status, error) {
    if (this.callbackFn) {
      this.callbackFn(form, event, xhr, status)
    }
    return
  }

}
