import BaseChart from './_base';
import Highcharts from 'highcharts';
import extend from 'lodash/extend';
import defaultsDeep from 'lodash/defaultsDeep';
import More from 'highcharts/highcharts-more';
import { highchartsLegend } from './highcharts_options';

More(Highcharts);

export default class Area extends BaseChart {
  constructor (container, props) {
    super(container, props);
    this.props = props;
    this.chart = null;
    this.options = this.props.options;
    this.data = this.props.data;

    // Take colors as a separate prop, but break them out into the series
    // objects since that's how HighCharts expects them.
    const _colors = this.props.colors;
    this.data.map(function (series, index) {
      series.data = series.data.map(function (value) {
        return Number(value);
      });
      if (!series.color && _colors.length > index) {
        series.color = _colors[index];
      }
    });

    this.init();
    return this.chart;
  }

  renderChart () {
    const theme = window.App.theme;
    const _options = this.options;

    // BEGIN magic numbers, don't touch
    const xValuesCount = this.data[0].data.length;
    let yAxisOffset = 0;
    if (xValuesCount <= 5) {
      yAxisOffset = -85;
    } else if (xValuesCount <= 6) {
      yAxisOffset = -75;
    } else if (xValuesCount <= 10) {
      yAxisOffset = -50;
    } else if (xValuesCount <= 20) {
      yAxisOffset = -30;
    } else if (xValuesCount <= 45) {
      yAxisOffset = -15;
    } else {
      yAxisOffset = -5;
    }
    const leftMargin = 80 + yAxisOffset;
    // END magic numbers, don't touch

    const chartOptions = defaultsDeep(_options, {
      chart: {
        type: 'area',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        style: {
          fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
          color: theme.colors.defaults.chart.labelsBright
        },
        height: _options.dimensions?.height || this.container.clientHeight || null,
        width: _options.dimensions?.width || this.container.clientWidth || null,
        marginTop: _options.margin?.top || null,
        marginRight: _options.margin?.right || 0,
        marginBottom: _options.margin?.bottom || null,
        marginLeft: _options.margin?.left || leftMargin
      },
      xAxis: {
        crosshair: false,
        lineWidth: 0,
        lineColor: 'transparent',
        gridLineColor: 'transparent',
        minorGridLineWidth: 0,
        minorTickLength: 0,
        tickLength: 0,
        labels: {
          rotation: -90,
          style: {
            color: theme.colors.defaults.chart.labelsBright
          }
        }
      },
      yAxis: {
        title: {
          text: _options.inrevCustom.yAxisTitle,
          style: {
            color: theme.colors.defaults.chart.labelsBright
          },
          margin: 5
        },
        crosshair: false,
        lineWidth: 0,
        lineColor: 'transparent',
        gridLineColor: 'transparent',
        minorGridLineWidth: 0,
        minorTickLength: 0,
        tickLength: 0,
        labels: {
          style: {
            color: theme.colors.defaults.chart.labelsBright
          },
          format: '{value}%'
        },
        max: 100,
        min: 0,
        offset: yAxisOffset,
        reversedStacks: false
      },
      title: {
        text: _options.showTitle && this.props.title ? this.props.title : null,
        verticalAlign: 'middle',
        useHTML: true,
        floating: true
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        series: {
          animation: _options.animation
        },
        area: {
          stacking: 'normal',
          lineWidth: 0,
          marker: {
            enabled: false
          },
          fillOpacity: 1
        }
      },
      credits: {
        enabled: false
      },
      series: this.data,
      exporting: {
        buttons: {
          contextButton: { enabled: false }
        }
      },
      legend: extend(
        highchartsLegend(true), {
          symbolRadius: 0, // Square legend swatches.
          itemStyle: {
            fontWeight: 'normal'
          },
          useHTML: true,
          labelFormatter: function () {
            let legendIcon;
            if (this.visible) {
              legendIcon = ' <i class="icon--sm icon-inrev-legend-show" />';
            } else {
              legendIcon = ' <i class="icon--sm icon-inrev-legend-hide" />';
            }
            return this.name + legendIcon;
          }
        })
    });
    this.chart = Highcharts.chart(this.container, chartOptions);
    return this.chart;
  }
}
