import { h, Component, render } from 'preact'
import { isObject } from 'util'

export default class InputRadius {
  constructor (id, props) {
    this.id = id
    this.element = document.getElementById(this.id)

    if (!this.element) {
      console.debug(`InputRadius could not find id: ${id}`)
    } else {
      this.props = props
      this.props.mapContainerId = `${this.id}-map`
      this.props.inputsContainerId = `${this.id}-inputs`
      this.renderComponent()
    }
  }

  renderComponent () {
    render(
      <InputRadiusComp {...this.props} />,
      this.element
    )
  }
}

class InputRadiusComp extends Component {
  constructor(props) {
    super(props)
    this.state = this.props
    this.inputElements = []
  }

  componentDidMount() {
    App.Filters.callbacks[this.props.inputsContainerId] = (key, value) => {
      this.setState({[key]: value, mapVisible: true})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.mapVisible) {
      if (!this.map) {
        this.initializeMap()
        this.initializeLayers()
        this.setState({mapInitialized: true})
      }
      this.initializeTooltip()
      const { lngValue, latValue, radiusValue } = this.state
      if (+lngValue !== +prevState.lngValue ||
          +latValue !== +prevState.latValue ||
          +radiusValue !== +prevState.radiusValue) {
        this.addCircle(this.buildCircle([latValue, lngValue], radiusValue))
      }
    }
  }

  buildCircle(latlng, radius) {
    return L.circle(latlng, {radius: radius, ...this.props.circleStyle})
  }

  addCircle(circle) {
    if (!this.map) console.debug('Circle cannot be added because map instance is not defined')
    // Remove existing circle
    if (this.circle) {
      this.circle.remove()
    }

    // Update map and form fields
    this.map.addLayer(circle)
    this.setFormValues(circle)

    // Enable editing of the circle
    circle.enableEdit()

    // Store the circle for future reference
    this.circle = circle
  }

  initializeLayers() {
    const radius = this.state.radiusValue
    const lat = this.state.latValue
    const lng = this.state.lngValue
    const { circleStyle } = this.props

    if (radius && lat && lng) {
      this.addCircle(L.circle([lat, lng], {radius: radius, ...circleStyle}))
    }
  }

  setFormValues(circle) {
    this.setState({
      radiusValue: circle && circle._mRadius,
      latValue: circle && circle._latlng.lat,
      lngValue: circle && circle._latlng.lng,
    })

    // Manually trigger change event on hidden fields
    const scope = this.props.inputsContainerId
    const hiddenInputs = $(`#${scope}`).find('input[type=hidden]')
    hiddenInputs.each((_, input) => $(input).trigger('change'))
  }

  initializeMap() {
    const { mapContainerId, center, zoom, defaultRadius, circleStyle } = this.props
    const mapOptions = {
      zoomControl: true,
      editable: true,
    }

    this.map = L.map(mapContainerId, mapOptions).setView(center, zoom)
    const tileSource = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'

    L.tileLayer(tileSource, {
      attribution: '© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      maxZoom: 18
    }).addTo(this.map)

    this.map.on('click', (e) => {
      if (!this.circle) {
        // Place a circle with a radius based on the zoom level and latitude to
        // get a circle that partially fills the screen no mater the viewport.
        const zoom = this.map.getZoom()
        const lat = this.map.getCenter().lat
        const metersPerPx = 156543.03392 * Math.cos(lat * Math.PI / 180) / Math.pow(2, zoom)

        const radius = metersPerPx * defaultRadius
        this.addCircle(this.buildCircle(e.latlng, radius))
      }
    })

    this.map.on('editable:vertex:dragend', (e) => {
      this.addCircle(e.layer)
    })
  }

  initializeTooltip() {
    const { mapContainerId } = this.props
    const noCircle = !this.circle
    this.tooltip = L.DomUtil.get(`${mapContainerId}-tooltip`)

    const addTooltip = (e) => {
      L.DomEvent.on(document, 'mousemove', moveTooltip)
      this.tooltip.innerHTML = this.circle ?
        'Drag the handles to adjust the circle.' :
        'Click on the map to select a location.'
      this.tooltip.style.display = 'block'
    }

    const removeTooltip = (e) => {
      this.tooltip.style.display = 'none'
      L.DomEvent.off(document, 'mousemove', moveTooltip)
    }

    const moveTooltip = (e) => {
      this.tooltip.style.left = e.clientX + 20 + 'px'
      this.tooltip.style.top = e.clientY - 10 + 'px'
    }

    this.map.on('mouseover', addTooltip)
    this.map.on('mouseout', removeTooltip)
    addTooltip()
  }

  handleInput(e) {
    const value = Number(e.target.value)
    const key = e.target.dataset.key
    this.setState({[key]: value})
    this.initializeLayers()
  }

  setMapVisibility(visible) {
    let newState = {mapVisible: visible}

    if (!visible) {
      this.setFormValues(null)
      this.circle && this.circle.remove()
      this.circle = null
      this.map && this.map.remove()
      this.map = null
    }

    this.setState(newState)
  }

  render() {
    const {
      mapContainerId,
      inputsContainerId,
      mapHeight,
      radiusPlaceholder,
      latPlaceholder,
      lngPlaceholder,
      radiusName,
      latName,
      lngName,
      inputClasses,
      inputType,
      label
    } = this.props
    const { radiusValue, latValue, lngValue, mapVisible } = this.state
    const _inputClasses = `c-input ${inputClasses ? inputClasses.join(' ') : ''}`
    return (
      <div className='c-input-radius'>
        <div className='c-input-radius__label'>
          <a href='#' onClick={() => this.setMapVisibility(!mapVisible)} className='c-link c-link--with-icon'>
            { mapVisible ?
              <span>Clear<i className='icon-inrev icon-inrev-close'/></span>
              :
              <span>Pick a location<i className='icon-inrev icon-inrev-guidelines'/></span>
            }
          </a>
        </div>
        <div className='c-input-radius__inputs' id={inputsContainerId}>
          <input
            className={_inputClasses}
            name={radiusName}
            id={radiusName}
            type={inputType}
            placeholder={radiusPlaceholder || 'Radius'}
            value={radiusValue}
            data-key={'radiusValue'}
            onInput={this.handleInput.bind(this)}
            ref={el => this.inputElements.push(el)} />
          <input
            className={_inputClasses}
            name={latName}
            id={latName}
            type={inputType}
            placeholder={latPlaceholder || 'Latitude'}
            value={latValue}
            data-key={'latValue'}
            onInput={this.handleInput.bind(this)}
            ref={el => this.inputElements.push(el)} />
          <input
            className={_inputClasses}
            name={lngName}
            id={lngName}
            type={inputType}
            placeholder={lngPlaceholder || 'Longitude'}
            value={lngValue}
            data-key={'lngValue'}
            onInput={this.handleInput.bind(this)}
            ref={el => this.inputElements.push(el)} />
        </div>

        {mapVisible && <div id={mapContainerId} style={{height: mapHeight}} />}
        {mapVisible && <div id={`${mapContainerId}-tooltip`} className='c-input-radius__tooltip' />}

      </div>
    )
  }
}

InputRadiusComp.defaultProps = {
  mapHeight: '250px',
  circleStyle: {
    color: '#0633a0',
    fillColor: '#0633a0',
  }
}
