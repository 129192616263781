import { render, h } from 'preact'
import { deepCopy, createEvent } from '../utils'
import { throttle } from 'lodash'
import MultiSelectInputContainer from './MultiSelectInputContainer'


// A few notes about this Class
// There are a few workarounds to deal with not having direct access
// to the state of the `DropdownTreeSelect` Component
// We keep a seperate object tracking what items are selected
// and establish a few utility methods that help combine get/set sets of
// selections and (de)select items
// Important note: when the selections are modified within this class, the
// Component is removed and re-rendered with the new dataset —
// It's not ideal but alternatives have proven to be difficult.
// We should plan to refactor this someday such that the component handles
// these changes if possible.

export default class MultiSelectInput {
  constructor(elId, options={}) {
    this.elId = elId
    this.options = options
    this.selections = []
    this.allSelected = false
    this.init()
  }

  init(_data=null, update=false, eventToTrigger=null) {
    App.MultiSelect.instances[this.elId] = this
    const el = this.getElement()
    let data = null
    if (_data && _data.length) {
      data = _data
      // Remove old instance
      el.innerHTML = ''
    } else if (!update) {
      this.originalData = el.dataset.items ? JSON.parse(el.dataset.items) : null
      data = this.originalData
    } else {
      return
    }

    if (data) {
      this.renderInput(el, data, {
        disabled: el.dataset.disabled === 'true',
        readOnly: el.dataset.readOnly,
        name: el.dataset.name,
        showSelectAll: el.dataset.showSelectAll === 'true',
        showSelectionList: el.dataset.showSelectionList === 'true',
        placeholder: el.dataset.placeholder,
        id: this.elId,
        updateSelected: this.updateSelected.bind(this),
      })
      if (update && eventToTrigger) {
        this.triggerFormEvent(eventToTrigger)
      }
    } else {
      console.error('No data for input');
    }
  }

  getElement() {
    return document.getElementById(this.elId)
  }

  triggerFormEvent(eventName) {
    const el = this.getElement()
    const form = $(el).closest('form')
    if (form) {
      $(form).trigger(eventName)
    } else {
      return
    }
  }

  update(data, eventToTrigger='change') {
    this.init(data, true, eventToTrigger)
  }

  updateSelected(data) {
    this.selections =  data
  }

  getOriginalData() {
    return this.originalData
  }

  getDataWithSelections() {
    const checkedValues = this.getSelections().map(s => s.value)
    let newData = [...this.originalData]
    const checkItems = (items) => {
      items.forEach( d => {
        d.checked = checkedValues.includes(d.value)
        if (d.children && d.children.length)
          checkItems(d.children)
      })
    }
    checkItems(newData)
    return newData
  }

  getSelections() {
    return this.selections
  }

  combineSelections(otherInstanceSelections=[]) {
    const currentSet = this.getOriginalData()
    const newDataSet = deepCopy(currentSet)
    const updateProps = (items=[], addedProps=[]) => {
      const propsToRemove = ['child', 'parent', '_depth', '_id', '_parent', '_children']
      items.forEach((item) => {
        propsToRemove.forEach( prop => delete item[prop] )
        addedProps.forEach(([name, value]) => item[name] = value)
      })
    }
    if (!otherInstanceSelections.length) return currentSet
    deepCopy(otherInstanceSelections).forEach(selection => {
      const item = newDataSet.find(d => selection.value.includes( d.value ) )
      if (item) {
        let { checked=true } = selection
        const { children=[] } = item
        const child = children.find(c => selection.value.includes(c.value))
        if (child) {
          child.checked = !checked
        } else {
          if (!item.children) {
            item.checked = !checked
          } else {
            item.expanded = true
            selection.className = 'child city'
            item.children.unshift(selection)
          }
        }
        updateProps(item.children)
      } else {
        selection.children = []
        selection._children = []
        updateProps([selection])
        newDataSet.unshift(selection)
      }
    })
    this.update(newDataSet)
  }

  // Receive an item to remove externally (probably within one of the child component)
  removeSelection(itemToRemove) {
    const _currentData = this.getDataWithSelections()
    const isAMatch = (item1, item2) => {
      return item1.value === item2.value
    }
    const dataWithSelectionRemoved = _currentData.map((item) => {
      if (isAMatch(item, itemToRemove)) {
        item.checked = false
      } else if (item.children && item.children.length) {
        const foundChild = item.children.find((child) => {
          return isAMatch(child, itemToRemove)
        })
        if (foundChild) {
          foundChild.checked = false
        }
      }
      return item
    })
    this.allSelected = false
    this.update(dataWithSelectionRemoved)
  }

  checkItems(items, checkedState) {
    items.forEach( (item) => {
      item.checked = checkedState
      if (item.children && item.children.length) {
        item.children = this.checkItems(item.children, checkedState)
      }
    })
    return items
  }

  deselectAll() {
    this.allSelected = false
    this.update(this.checkItems([...this.originalData], false))
  }

  selectAll() {
    this.allSelected = true
    this.update(this.checkItems([...this.originalData], true))
  }

  isDisabled() {
    return this.getElement().dataset.disabled == 'true'
  }

  isEnabled() {
    return !this.isDisabled()
  }
  disable() {
    if (this.isDisabled()) { return; }
    this.getElement().dataset.disabled = 'true';
    this.init(this.originalData)
  }

  enable() {
    if (this.isEnabled()) { return; }
    this.getElement().dataset.disabled = 'false';
    this.init(this.originalData)
  }

  renderInput(el, data, props) {
    render( <MultiSelectInputContainer
      data={data}
      allSelected={this.allSelected}
      {...props}
    />, el )
  }

}
