// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

import Dropzone from 'dropzone'
Dropzone.autoDiscover = false;

App.Dropzone = class {
  constructor(props) {
    return new Dropzone(document.querySelector(props.el), {
      timeout: 300000,
      addRemoveLinks: true,
      clickable: ['.js-dropzone-btn', '.dropzone'],
      init: function() {
        this.on('addedfile', (file) => {
          $('.upload-messages').html('')
          $('.dz-message').html('Uploading file...')
        })
        this.on('removedfile', file => console.log(file))
        this.on('error', function (file, error, xhr) {
          if (file.xhr.status === 422)
            window.location.reload()
        })
        this.on('success', function (file, response) {
          console.log(file.xhr.status)
          console.log(response)
          // if asset file created we get a response back
          if (response && response.id && response.asset_file_path) {
            window.location = response.asset_file_path
          } else if (response && response.id && response.esg_file_path) {
            window.location = response.esg_file_path
          } else if (response && response.upload_file_path) {
            window.location = response.upload_file_path
          }
          // otherwise if we got 204, update was successful
          else if (file && file.xhr && file.xhr.status === 204) {
            window.location.reload()
          }
        })
      }
    })
  }
}

$(document).on('click', '.collapse', (e) => {
  const target = $(e.target).closest('tr').data('target')
  $(`.${target}`).fadeToggle()
})
