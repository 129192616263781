export default class ValueSummary {
  constructor(props) {
    this.formEl = props.formEl,
    this.summaryEl = props.summaryEl,
    this.items = props.items
    this.registerEvents()
  }

  registerEvents() {
    this.items.forEach(item => {
      return
      // Add events for each related input and call callback on change/input
      // Also call `update` to take care of any visual changes that need to
      // occur to let user know the value changed
      item.inputs.forEach((input) => {
        const callBack = () => {
          this.update(item, input)
          item.callBack()
        }
        input.addEventListerner('change', callBack)
        input.addEventListerner('input', callback)
      })
    })
  }

  update() {
    // highlight and update value
    return
  }
}
