import { querySelectorAll } from '../utils'
import { every, some, flatten, uniq } from 'lodash'

/**
 * Init dependent options - run through inputs on page load to check/modify
 * check state and add events on all related inputs
 */
export function initDependentOption() {
  querySelectorAll('[data-visibility-trigger]').forEach(el => {
    showHideInput(el, null)
    const inputNames = getTriggers(el).map( t => Object.keys(t) )
    addListeners( _.uniq( _.flatten( inputNames ) ), el )
  })
}
/**
 * Add event listeners for each element with callback that show's or hides `changeEl`
 *
 * based on its triggers object
 * @param  {Array} parentElNames=[]
 * @param  {DOM Node} changeEl
 */
const addListeners = (parentElNames=[], changeEl) => {
  parentElNames.forEach(parentElName => {
    // Matches normal inputs
    var selector = `input[id$=attributes_${parentElName}]`
    // Matches multi option checkboxes
    selector += `,input[type=checkbox][name$=\\[${parentElName}\\]\\[\\]]`
    // Matches boolean inputs
    selector += `,label[for$=_${parentElName}]`
    // Matches radiogroups inputs
    selector += `,input[data-input-name$=${parentElName}]`
    // Matches selects  -- NOTE: needs to be last selector
    selector += `,select[name$=\\[${parentElName}\\]`

    document.querySelectorAll(selector).forEach(el => {
      switch(el.nodeName) {
        case 'SELECT':
          $(el).on('select2:select change', e => showHideInput( changeEl, e ))
          break
        case 'LABEL':
        case 'INPUT':
          $(el).on('input change', e => showHideInput( changeEl, e ))
          break
      }
    })
  })
}
/**
 * Show or hide element based on result of `checkConditionals`
 *
 * @param  {DOM Node} el
 */
const showHideInput = (el, event) => {
  let triggers = getTriggers(el)
  let classActionMethod = 'add'
  const transitionClass = 'b-form__group--transitioning'
  if (checkConditionals(triggers)) {
    classActionMethod = 'remove'
  }
  const parentElement = el.closest('.b-form__group')
  if (parentElement) {
    parentElement.classList[classActionMethod]('b-form__group--hidden')
    parentElement.classList.add(transitionClass)
    setTimeout(() => { parentElement.classList.remove(transitionClass) }, 2000)
  }
}

/**
 * @param  {String} name
 */
const getSelectedElementByName = name => {
  let el = document.querySelector(`[name*=${name}]`)
  if ( el && typeof(el.value) == 'undefined' ) {
    // FIXME: this is a hack for the radio button groups recently introduced.
    // it finds the first selected or checked input when a prior input wasn't found
    el = document.querySelector(`input[name*=${name}]:checked,select[name*=${name}] option:checked`)
  }
  return el
}

/**
 * Get Triggers of element stored as data-attr
 *
 * @param  {DOM Node} el
 */

const getTriggers = el => JSON.parse(el.dataset.visibilityTrigger)

/**
 *  Iterate through `triggers` and determine if conditions are met based on structure
 *  below
 *
 *  `triggers` is an array of objects
 *  the object keys represent the controlling fields, the values are
 *  the values that trigger a show for the current element.
 *  keys within a object should be ANDed
 *  objects within an array should be ORed.
 *  If an array is given as the value, it should be treated as OR
 *
 *  For example:
 *  [
 *    {country_coverage: "multi country", target_regional_strategy: ["Europe", "Asia Pacific"]},
 *    {country_coverage: "multi country", target_debt_regional_strategy: ["Europe", "Asia Pacific"]}
 *  ]
 *  is equivalent to:
 *  show if
 *  (`country_coverage` == 'multi country' &&
 *    (`target_regional_strategy` == 'Europe' || `target_regional_strategy` == 'Asia Pacific'))
 *    ||
 *  (`country_coverage` == 'multi country' &&
 *    (`target_debt_regional_strategy` == 'Europe' || `target_debt_regional_strategy` == 'Asia Pacific'))
 *
 * @param  {Array} triggers
 */
const checkConditionals = (triggers) => {
  const truths = []
  triggers.forEach((trigger) => {
    const keys = Object.keys(trigger)
    const states = []
    keys.forEach(inputName => {
      let el = getSelectedElementByName(inputName)
      if (!el) {
        states.push(false)
        return
      }
      // Get array with any value that would trigger the field to appear.
      var triggerValues = [];
      if (typeof(trigger[inputName]) === 'object') {
        triggerValues = trigger[inputName]
      } else {
        triggerValues = [trigger[inputName]]
      }
      // Get array with all the element values (in case it supports multiple like checkboxes)
      var elementValues = [];
      $(el).closest('.b-collection').find('input[type=checkbox]:checked').each(function() {
        elementValues.push($(this).val());
      });
      if (elementValues.length == 0) {
        elementValues = [el.value];
      }
      // Check if any of the trigger values matches any of the element values.
      var triggerMatches = []
      for (var elementValue of elementValues) {
        triggerMatches.push(triggerValues.includes(elementValue))
      }
      states.push(_.some(triggerMatches))
    })
    truths.push( _.every(states) )
  })
  return _.some(truths)
}
