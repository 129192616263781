export function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

export function randomID() {
  return '_' + Math.random().toString(36).substr(2, 9);
}

export const importAll = (r) => r.keys().forEach(r)

export const querySelectorAll = (selector, context=document) => {
  return [ ...context.querySelectorAll(selector) ]
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

/**
 * Creates a custom supporting IE11
 * https://caniuse.com/#feat=customevent
 * In IE11 CustomEvent and Event cannot be instantiated with `new`
 *
 * @param  {String} name - name of event
 * @param  {Object} detail - object declaring data available in
 *                           event's callback
 */
export const createEvent = (name, detail={}, canBubble=true, cancelable=true) => {
  let event = null
  if (typeof(CustomEvent) === 'function') {
    event = new CustomEvent(name, detail)
  } else {
    event = document.createEvent('CustomEvent')
    event.initCustomEvent('submit', canBubble, cancelable, detail)
  }
  return event
}

export const xhrResponseURL = (xhr) => {
  const url = xhr.responseURL || xhr.getResponseHeader("X-Request-URL");
  return url;
}
