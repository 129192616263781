import { h, Component, render, options } from 'preact'
import Slider from 'bootstrap-slider'

export default class InputRange {
  constructor (id, props) {
    this.id = id
    this.element = document.getElementById(this.id)
    if (!this.element) {
      console.debug(`RangeInput could not find id: ${id}`)
    } else {
      this.props = props
      this.props.rangeId = `${this.id}-slider`
      this.renderComponent()
    }
  }

  renderComponent () {
    render(
      <InputRangeComp {...this.props} />,
      this.element
    )
  }
}

class InputRangeComp extends Component {
  constructor (props) {
    super(props)
    this.state = this.props
    this.options = {
      showSlider: props.showSlider === undefined ? true : props.showSlider
    }

    this.state.maxValueString = this.state.maxValue
    this.state.minValueString = this.state.minValue
    this.state.maxValue = this.state.maxValue || this.props.max
    this.state.minValue = this.state.minValue || this.props.min
  }

  componentDidMount () {
    this.initSlider()
  }

  componentDidUpdate () {
    this.initSlider()
  }

  initSlider () {
    if (!this.options.showSlider) return
    const id = `#${this.props.rangeId}`
    const sliderOptions = {
      tooltip: 'hide'
    }
    this.range = new Slider(id, sliderOptions)
    this.range.on('change', this.updateMinMax.bind(this))

    // Setup events to manually trigger update of range
    // (e.g. when filters are coppied)
    document.querySelector(`input[name="${this.props.minName}"]`)
      .addEventListener('update', (event) => {
        const val = event.detail.value
        if (val) {
          this.updateMinMax({newValue: [val, this.state.maxValue]})
        }
      })
    document.querySelector(`input[name="${this.props.maxName}"]`)
      .addEventListener('update', (event) => {
        const val = event.detail.value
        if (val) {
          this.updateMinMax({newValue: [this.state.minValue, val]})
        }
      })
  }

  updateMin (event) {
    const val = event.target.value
    this.setState({
      minValue: val === '' ? null : +val,
      minValueString: val,
    })
  }

  updateMax (event) {
    const val = event.target.value
    this.setState({
      maxValue: val === '' ? null : +val,
      maxValueString: val,
    })
  }

  updateMinMax (values) {
    const { newValue } = values
    const [min, max] = newValue
    this.setState({
      minValue: min,
      maxValue: max,
      minValueString: min,
      maxValueString: max,
    })
  }

  render () {
    const {
      rangeId,
      min,
      max,
      minPlaceholder,
      maxPlaceholder,
      minName,
      maxName,
      step='1',
      theme,
      inputClasses,
      betweenText='to',
      appendWith=null
    } = this.props
    const {
      minValue,
      maxValue,
      minValueString,
      maxValueString,
    } = this.state
    const _minPlaceholder = minPlaceholder || 'min'
    const _maxPlaceholder = maxPlaceholder || 'max'
    const _inputClasses = `c-input c-input-range__input ${inputClasses ? inputClasses.join(' ') : ''}`
    return (
      <div className='c-input-range'>
        <div className='c-input-range__inputs'>
          <div className='b-form__group'>
            <input
              className={_inputClasses}
              name={minName}
              id={minName}
              type='number'
              max={maxValue || max}
              min={min}
              step={step}
              placeholder={_minPlaceholder}
              value={minValueString}
              onInput={this.updateMin.bind(this)} />
            { appendWith ?
              <span class="b-form__label_addendum">{appendWith}</span>
              : null
            }
          </div>
          <span className='c-input-range__between'>{betweenText}</span>
          <div className='b-form__group'>
            <input
              className={_inputClasses}
              name={maxName}
              id={maxName}
              type='number'
              max={max}
              min={minValue || min}
              step={step}
              placeholder={_maxPlaceholder}
              value={maxValueString}
              onInput={this.updateMax.bind(this)} />
              { appendWith ?
                <span class="b-form__label_addendum">{appendWith}</span>
                : null
              }
          </div>
        </div>
        { this.options.showSlider ?
          <div className={`c-slider c-input-range__slider c-slider--${theme}`}>
            <input
              id={ `${rangeId}` }
              type='text'
              value=''
              data-slider-min={ min }
              data-slider-max={ max }
              data-slider-step={step}
              data-slider-value={`[${minValue || min}, ${maxValue || max}]`}
              onChange={this.updateMinMax.bind(this)}/>
          </div>
        : null }
      </div>
    )
  }
}
