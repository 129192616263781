import constants from '../constants';

export default class BaseChart {
  constructor (container = null, props = {}) {
    if (container && typeof (container) === 'string') { this.container = document.getElementsByClassName(container).item(0); } else if (props.el && typeof (props.el) === 'string') {
      this.container = document.getElementById(props.el);
    } else if (container) {
      this.container = container;
    } else {
      console.debug('Could not find container element for chart');
    }
    this.registerEvents();
    this.registerChart();
    this.init();
    return this.chart;
  }

  init () {
    // Set timeout to allow UI to fully render
    // Sometimes, the containers for the charts are not the
    // correct size when the chart's class is instantiated
    setTimeout(() => {
      this.renderChart();
    }, 20);
  }

  registerChart () {
    App.Charts.instances.push(this);
  }

  registerEvents () {
    $(window).resize(this.resizeToContainer.bind(this));
  }

  reflow () {
    this.chart.reflow();
  }

  resizeToContainer () {
    if (this.container && this.chart) {
      setTimeout(() => {
        const { clientWidth, clientHeight } = this.container;
        this.chart.setSize(clientWidth, clientHeight);
      }, constants.ANIMATION_DELAY);
    }
  }
}
