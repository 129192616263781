import moment from 'moment'

export default class DatePickerInput {
  constructor(id) {
    const field = document.getElementById(id)
    if (!field) {
      console.debug(`DatePickerInput could not find id: ${id}`)
    } else {
      const format = 'DD/MM/YYYY'
      let minDate = field.getAttribute('min')
      let maxDate = field.getAttribute('max')
      if (minDate) {
        minDate = moment(minDate).toDate()
      }
      if (maxDate) {
        maxDate = moment(maxDate).toDate()
      }
      const defaultDate = maxDate;

      new Pikaday({field, format, minDate, maxDate, defaultDate})
      this.registerEvents()
    }
  }

  registerEvents() {
  }
}
