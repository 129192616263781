//#############
// Ajax modals
//#############

export default class AjaxModal {
  constructor() {
    this.modal = $(".modal[data-ajax-modal]");
    this.loading = this.modal.find("[data-ajax-modal-loading]");
    this.content = this.modal.find("[data-ajax-modal-content]");
    this.linkTriggersSelector = '[data-loads-in-ajax-modal]';
    this.formTriggersSelector = '[data-submits-to-ajax-modal]';
    return this.listen();
  }

  listen() {
    this._registerLinks();
    this._registerForms();
    this._registerUjs();
    return this._registerClose();
  }

  // link_to url, {method: 'post', data: {ujs_loads_in_ajax_modal: true}}
  _registerUjs() {
    $(document).on('ajax:before', '[data-ujs-loads-in-ajax-modal]', () => {
      this.loading.show();
      this.current_document_url = document.URL;
    });
    $(document).on('ajax:complete', '[data-ujs-loads-in-ajax-modal]', (event) => {
      window.history.replaceState({}, '', this.current_document_url);
      const response =  event.detail[0].response
      this.loading.hide();
      this._setModalTitle();
      this.content.html(response);
      this.open();
    });
  }

  _registerLinks() {
    return $('body').on('click', this.linkTriggersSelector, event => {
      const form = event.currentTarget;
      var shouldMakeRequest = true;
      var confirmationText = '';
      event.preventDefault();
      this.loading.show();
      this.open();
      if (confirmationText = form.getAttribute('data-ajax-confirm')) {
        shouldMakeRequest = confirm(confirmationText);
      }
      if (shouldMakeRequest) {
        return $.ajax({
          url: form.getAttribute("href"),
          dataType: 'html',
          headers: {
            'X-AJAX-MODAL': true
          },
          complete: (xhr, status) => {
            this.loading.hide();
            this.content.html(xhr.responseText);
            this._setModalTitle();
            return this.open;
          }
        });
      } else {
        return true;
      }
    });
  }

  _registerForms() {
    // scope.find(@formTriggersSelector).attr('data-remote', true)
    return $('body').on('submit', this.formTriggersSelector, event => {
      const form = event.currentTarget;
      event.preventDefault();
      $.ajax({
        url: form.getAttribute('action'),
        type: form.getAttribute('method'),
        dataType: 'html',
        data: $(form).serialize(),
        headers: {
          'X-AJAX-MODAL': true
        },
        complete: (xhr, status) => {
          this.loading.hide();
          this.content.html(xhr.responseText);
          this._setModalTitle();
          return this.open;
        }
      });
      return false;
    });
  }

  // Move title element to the header of the modal.
  _setModalTitle() {
    const header_selector = '[data-ajax-modal-title]'
    const body_selector = '[data-ajax-modal-content]'
    const title_selector = '.modal-title';

    const body_title = this.content.find(title_selector);
    const header = this.modal.find(header_selector);

    if (body_title.length && header.length) {
      header.find(':header').remove(); // h tags
      header.find(title_selector).remove(); // .modal-title tags.

      body_title.hide();
      header.prepend(body_title.first());
      header.find(title_selector).show();
    }
  }

  // $('body').on 'ajax:complete', @formTriggersSelector, (event, data, status, xhr) =>
  //   @_registerForms @content

  //maybe don't need this for bootstrap
  _registerClose() {
    return this.modal.on('click', '[data-ajax-modal-close]', () => {
      this.modal.modal('hide');
      return this.reset();
    });
  }

  open() {
    return this.modal.modal('show');
  }

  reset() {
    this.content.html("");
    return this.loading.show();
  }
}
