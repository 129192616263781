import StackedBar from './stacked_bar';
import Line from './line';
import LineMultiAxis from './line_multi_axis';
import Geo from './geo';
import BarLine from './bar_line';
import Pie from './pie';
import BoxPlot from './box_plot';
import Area from './area';

App = App || {};
App.Charts = {};

App.Charts.StackedBar = StackedBar;
App.Charts.Line = Line;
App.Charts.LineMultiAxis = LineMultiAxis;
App.Charts.Geo = Geo;
App.Charts.BarLine = BarLine;
App.Charts.Pie = Pie;
App.Charts.BoxPlot = BoxPlot;
App.Charts.Area = Area;

App.Charts.instances = [];
