export function loadingAnimation(marginTop=0, message='', fullBleed=false) {
  return `
    <div class="c-loading mt-${marginTop} ${fullBleed ? 'c-loading--full' : ''}">
      <div class="c-loading__animation">
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
        <div class="rect4"></div>
        <div class="rect5"></div>
      </div>${message}
    </div>
  `
}
export function loadingBar() {
  return `<div class="c-loading-bar"></div>`
}
