import { xhrResponseURL } from './utils'

let SignOff;
App.SignOff = (SignOff = class SignOff {
  constructor() {
    this.registerEvents();
  }

  registerEvents() {
    $(document).on('ajax:success', '#js-sign-off-page', event => {
      const [data, status, xhr] = event.detail;
      const newFragment = $(data).find('#js-sign-off-page').html();
      $('#js-sign-off-page').html(newFragment);
      const responseURL = xhrResponseURL(xhr)
      if (responseURL) {
        history.replaceState(null, '', responseURL);
      }
    });
  }
});
