import BaseChart from './_base';
import {
  highchartsNavigation,
  highchartsDownloadContextButton,
  highchartsLegend
} from './highcharts_options';

export default class Geo extends BaseChart {
  constructor (containerId, props) {
    super(document.getElementById(containerId), props);
    this.props = props;
    this.init();
  }

  renderChart () {
    const { country_data, point_data, units } = this.props;
    this.chart = Highcharts.mapChart(this.container.id, {
      chart: {
        map: 'custom/world-highres',
        animation: false
      },
      series: [{
        name: 'Market Value',
        data: country_data,
        joinBy: ['name', 'name'],
        tooltip: {
          headerFormat: '',
          pointFormat: `
            <span class='d-block font-weight-bold mb-2'>{point.name}</span>
            <span class='d-block'>Market Value: <b>{point.market_value}</b>${units}</span>
            <span class='d-block'>Number of assets: <b>{point.number_of_assets}</b></span>`
        }
      },
      {
        name: 'Assets',
        color: 'black',
        type: 'mappoint',
        turboThreshold: 0,
        data: point_data,
        tooltip: {
          headerFormat: '',
          pointFormat: `
            <span class='d-block font-weight-bold mb-2'>{point.asset_name}</span>
            <span class='d-block'>Asset ID: <b>{point.asset_id}</b></span>
            <span class='d-block'>Address: <b>{point.address1} {point.address2}, {point.postal_code}</b></span>`
        }
      }],
      mapNavigation: {
        enabled: true,
        enableDoubleClickZoom: true,
        enableMouseWheelZoom: false
      },
      tooltip: {
        useHTML: true,
        shadow: false
      },
      title: {
        text: ''
      },
      legend: highchartsLegend(false),
      credits: {
        enabled: false
      },
      exporting: {
        buttons: {
          contextButton: highchartsDownloadContextButton(true)
        },
        fallbackToExportServer: false,
        url: 'disabled'
      },
      navigation: highchartsNavigation()
    });
    // zoom in to europe by default
    this.chart.xAxis[0].setExtremes(4800, 4800, false);
    this.chart.yAxis[0].setExtremes(-9300, -7500, false);
    this.chart.redraw();
  }
}
