import { h, render, Component } from 'preact'
import { randomID } from './utils'

// IE 11 polyfill for element remove
// https://stackoverflow.com/questions/20428877/javascript-remove-doesnt-work-in-ie
if (!('remove' in Element.prototype)) {
  Element.prototype.remove = function() {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}

export default class {
  constructor(container='body') {
    this.container = container
    this.id = randomID()
  }

  /**
   * add - Render a Preact Component with props from method
   *       And add event to handle removal
   *
   * @param  {Object} props Title, message
   */
  add(props) {
    props.id = this.id
    render(
      <Toast {...props} />,
      document.querySelector(this.container)
    )
    $(`#${this.id}`).on('hide.bs.toast', () => {
      console.log('hide.bs.toast')
    })
    $(`#${this.id}`).on('hidden.bs.toast', () => {
      console.log('hidden.bs.toast')
      this.remove()
    })
  }

  /**
   * remove - Remove DOM element
   */
  remove() {
    const el = document.getElementById(this.id)
    if (el) {
      el.remove()
    }
  }

  /**
   * updateState - Update state of toast - change loader, title and message
   * @param  {String}  state        Updated state
   */
  updateState(state) {
    $(`#${this.id}`).trigger(state)
  }
}

class Toast extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      title: props.title,
      message: props.message,
    }
  }

  componentDidMount() {
    $(`#${this.props.id}`).on('downloaded', () => {
      console.log('downloaded')
      this.setState({
        loading: false,
        title: 'Download Complete',
        message: ''
      })
      setTimeout(() => $(this.el).toast('hide'), 3000)

    })
    $(this.el).toast({ autohide: false, animation: true})
    $(this.el).toast('show')
  }

  /**
   * loading - Loading UI
   *
   * @return {Node}
   */
  loading() {
    return (
      <div class="c-toast__loading">
        <div class="c-loading my-0">
          <div class="c-loading__animation">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
          </div>
        </div>
      </div>
    )
  }

  /**
   * complete - Complete UI
   *
   * @return {Node}
   */
  complete() {
    return (
      <div class="c-toast__complete">
        <i class='icon-inrev icon-inrev-check'/>
      </div>
    )
  }

  render({ id }, { loading, title, message }) {
    return(
      <div class='b-toasts' id={ id } >
        <div class="c-toast" ref={el => this.el = el} role="alert" aria-live="assertive" aria-atomic="true" data-autohide={false}>
          <div class='d-flex c-toast__wrapper'>
            { loading ? this.loading() : this.complete() }
            <div class='c-toast__dialog d-flex'>
              <div class="c-toast__content">
                <div class="c-toast__header">
                  <span class="c-toast__title">{ title }</span>
                  <button type="button" class="btn btn-link close c-toast__close" data-dismiss="toast" aria-label="Close" />
                </div>
                <div class="c-toast__body" > { message } </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}
