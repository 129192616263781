import BaseChart from './_base';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import _ from 'lodash';

import {
  highchartsNavigation,
  highchartsTooltipOptions,
  highchartsDownloadContextButton,
  highchartsLegend
} from './highcharts_options';

Exporting(Highcharts);
ExportData(Highcharts);
OfflineExporting(Highcharts);
export default class StackedBar extends BaseChart {
  constructor (container, data) {
    super(container);
    this.data = data;
    this.init();
    return this.chart;
  }

  renderChart () {
    const theme = window.App.theme;
    const labelStyle = {
      color: theme.colors.defaults.chart.labelsBright,
      fontSize: '12px'
    };
    const axisStyle = {
      allowDecimals: false,
      lineColor: 'transparent',
      tickPixelInterval: 30,
      lineWidth: 0,
      minorGridLineWidth: 0,
      minorTickLength: 0,
      minPadding: 0,
      maxPadding: 0,
      tickLength: 0,
      gridLineColor: 'transparent'
    };

    const chartOptions = {
      exporting: {
        buttons: {
          contextButton: highchartsDownloadContextButton(false)
        },
        fallbackToExportServer: false,
        url: 'disabled'
      },
      navigation: highchartsNavigation(),
      chart: {
        type: 'column',
        style: {
          fontFamily: '"Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif',
          color: theme.colors.defaults.chart.labelsDim
        }
      },
      title: { text: this.data.title.text },
      tooltip: _.extend(highchartsTooltipOptions(), {
        pointFormat: `
          <span class='b-chart-labels__label b-chart-labels__label--flex b-chart-labels__label--custom-swatch'>
            <span class='b-chart-labels__swatch' style="background:{series.color}" > </span>
            <span class='mr-1'> {series.name}: </span>
            <span class='b-chart-labels__value'>{point.y} ${this.data.units} ({point.percentage:.0f}%)</span>
          </span>
        `,
        valueDecimals: 1,
        outside: true
        // shared: true,
      }),
      plotOptions: {
        column: {
          stacking: 'percent',
          groupPadding: 0.1,
          borderWidth: 0,
          groupZPadding: 0,
          pointPadding: 0.05
        }
      },
      // colors: this.data.colors,
      xAxis: _.extend({
        title: {
          text: 'Financial period'
        },
        labels: {
          style: labelStyle
        },
        categories: this.data.xAxis.categories,
        tickColor: '#FFFFFF'
      }, axisStyle),
      yAxis: jQuery.extend({
        reversedStacks: false,
        title: {
          text: null
        },
        labels: {
          style: labelStyle,
          formatter: function () {
            return this.value + '%';
          }
        }
      }, axisStyle),
      credits: {
        enabled: false
      },
      series: this.data.series,
      legend: _.extend(
        highchartsLegend(true, undefined, true),
        {
          symbolPadding: 0,
          symbolWidth: 0,
          symbolHeight: 0,
          symbolRadius: 0,
          squareSymbol: false,
          itemStyle: {},
          labelFormatter: function () {
            let comp = 'comparison-a';
            if (this.userOptions.comparison === 'B') {
              comp = 'comparison-b';
            }
            const checkbox = '<span class="hc-custom-legend-checkbox" style="background-color:' + this.color + '"></span>';
            let legendIcon;
            if (this.visible) {
              legendIcon = ' <i class="ml-1 icon--sm icon-inrev-legend-show" />';
            } else {
              legendIcon = ' <i class="ml-1 icon--sm icon-inrev-legend-hide" />';
            }
            return '<span class="hc-custom-legend-item ' + comp + '">' + checkbox + this.name + legendIcon + '</span>';
          },
          reversed: false
        }
      )
    };
    this.chart = Highcharts.chart(this.container, chartOptions);
    return this.chart;
  }
}
