(function() {
  window.App || (window.App = {});
  var warn = App.warn || console.warn
  var alert_dialog = App.alert || window.alert
  var log_exception = App.error || console.error
  App.reportAjaxError = function(xhr, textStatus, errorThrown) {
    try {
      // Rails 5.1 ajax errors passed a single param
      if (xhr.detail) {
        var evt = xhr;
        var doc = evt.detail[0];
        var textStatus = evt.detail[1];
        var xhr = evt.detail[2];
      }
      console.debug('reportAjaxError', xhr);
      if (xhr.readyState == 0 || xhr.status == 0) {
        // request aborted
        return;
      }
      if ([401, 422].includes(xhr.status)) {
        // we got logged out
        window.location.reload();
        return;
      }
      // common JSON api error objects
      var json; try { json=JSON.parse(xhr.responseText) } catch {};
      var v = json && (json.status_message || json.message || json.error);

      // Or... just the raw text response
      v = v || xhr.responseText;
      var msg = v
      if (v) {
        msg = v;
      } else if (textStatus == 'timeout') {
        msg = 'The server is taking too long to respond. There may be a problem with your internet connection or we may under heavy load.';
      } else if (textStatus) {
        msg = "Unexpected Server Error: "+textStatus+"\n";
      }
      if (typeof Sentry !== 'undefined') {
        Sentry.captureMessage(msg)
      }
      if (xhr.getResponseHeader('Content-Type').match('text/javascript')) {
        $('body').append(v);
      } else {
        alert_dialog(msg)
      }
      //window.location.reload() // page is now in an inconsistent state
    } catch (e) {
      if (typeof Sentry !== 'undefined') {
        Sentry.captureException(e)
      } else {
        throw e
      }
    }
  }
}).call(this);
